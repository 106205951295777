<template>
    <div class="container">
        
        <h1 class="mr-1">{{ $t('dashboard-side-menu.reservations-made') }}</h1>

        <div class="list">
            
            <div v-for="reserva in reservationsData" class="row reservation" >
                
                <!-- Begin Office Version -->
                <div class="col-12 d-none d-sm-block">
                    
                    <div class="row">
                        <div class="col-12 title-reservas text-center pt-2">                        
                            {{dateFormat(reserva.fecha_inicio)}} - {{dateFormat(reserva.fecha_termino)}}
                        </div>
                    </div>
    
                    <div class="row px-3 pt-2">
                        <div class="col-2 text-center">{{$t('reservations.status')}}</div>
                        <div class="col-4">{{$t('reservations.date-place')}}</div>
                        <div class="col-3">{{$t('reservations.host')}}</div>
                        <div class="col-3 text-center">{{$t('reservations.details')}}</div>
                    </div>
    
                    <hr />
    
                    <div class="row p-3">
    
                        <div class="col-2 text-center">
                            {{changeStatus(reserva.status, reserva.fecha_pago)}}
                        </div>
                        
                        <div class="col-4">
                            <p>{{dateFormat(reserva.fecha_inicio)}} - {{dateFormat(reserva.fecha_termino)}}</p>
                            <p>{{languageName(reserva.accommodation.nombre)}}</p>
                            <p v-if="reserva.fecha_pago === null">{{$t('reservations.address-message')}}</p>
                            <p v-else>{{reserva.accommodation.direccion}}</p>
                        </div>
    
                        <div class="col-3">
                            <p>{{reserva.accommodation.user.nombre}}</p>
                            <p v-if="reserva.fecha_pago === null">{{$t('reservations.mail-message')}}</p>
                            <p v-else>{{reserva.accommodation.user.correo}}</p>
    
                            <p v-if="reserva.fecha_pago === null">{{$t('reservations.tel-message')}}</p>
                            <p v-else>{{reserva.accommodation.user.telefono}}</p>
                        </div>
    
                        <div class="col-3 text-center">
                            <a
                                v-if="reserva.status === 'accepted' && reserva.fecha_pago === null"
                                :href="buttonPago(reserva.apitoken, reserva.idreserva)"
                                class="btn btn-primary border-0"
                            >{{$t('reservations.go-payment')}}</a>
    
                            <p v-if="reserva.fecha_pago !== null">{{$t('reservations.already-paid')}}</p>
                            <p v-if="reserva.status === 'initiated'">{{$t('reservations.almost-done')}}</p>
                            <p v-if="reserva.status === 'rejected'">{{$t('reservations.declined')}}</p>
                            <p v-if="reserva.status === 'canceled'">{{$t('reservations.cancelled')}}</p>
                        </div>
                    </div>
                </div> <!-- End Office Version -->
    
                <!-- Begin  Mobile Version -->
                <div class="col-12  d-block d-sm-none">
                    <div class="row m-0">
                        <div class="col-12 title-reservas text-center pt-2 m-0">                        
                            {{dateFormat(reserva.fecha_inicio)}} - {{dateFormat(reserva.fecha_termino)}}
                        </div>
                    </div>
    
                    <div class="row px-3 d-none d-sm-block">
                        <div class="col-2 text-center">{{$t('reservations.status')}}</div>
                        <div class="col-4">{{$t('reservations.date-place')}}</div>
                        <div class="col-3">{{$t('reservations.host')}}</div>
                        <div class="col-3 text-center">{{$t('reservations.details')}}</div>
                    </div>
    
                    
    
                    <div class="row p-3">
                        <div class="col-12 col-sm-4">
                            <p>{{dateFormat(reserva.fecha_inicio)}} - {{dateFormat(reserva.fecha_termino)}}</p>
                            <p>{{languageName(reserva.accommodation.nombre)}}</p>
                            <p v-if="reserva.fecha_pago === null">{{$t('reservations.address-message')}}</p>
                            <p v-else>{{reserva.accommodation.direccion}}</p>
                        </div>
                        <hr/>
                        <div class="col-12 col-sm-3">
                            <p>{{reserva.accommodation.user.nombre}}</p>
                            <p v-if="reserva.fecha_pago === null">{{$t('reservations.mail-message')}}</p>
                            <p v-else>{{reserva.accommodation.user.correo}}</p>
    
                            <p v-if="reserva.fecha_pago === null">{{$t('reservations.tel-message')}}</p>
                            <p v-else>{{reserva.accommodation.user.telefono}}</p>
                        </div>
                        <hr/>
                        <div class="col-12 col-sm-3">
                            <a
                                v-if="reserva.status === 'accepted' && reserva.fecha_pago === null"
                                :href="buttonPago(reserva.apitoken, reserva.idreserva)"
                                class="btn btn-primary border-0"
                            >{{$t('reservations.go-payment')}}</a>
    
                            <p v-if="reserva.fecha_pago !== null">{{$t('reservations.already-paid')}}</p>
                            <p v-if="reserva.status === 'initiated'">{{$t('reservations.almost-done')}}</p>
                            <p v-if="reserva.status === 'rejected'">{{$t('reservations.declined')}}</p>
                            <p v-if="reserva.status === 'canceled'">{{$t('reservations.cancelled')}}</p>
                        </div>
                    </div>
                </div> <!-- End Mobile Version -->
                
            </div>
    
            <!-- If the user has no reserves, show a message -->
            <h4 v-if="reservationsData.length === 0" class="text-center">
                {{$t('reservations.no-reservations')}}
            </h4>
            
        </div>

        <Paginator
            :items="madeReservations"
            :fetchMethod="fetchMadeReservations"
        />
        
<!--        TODO: create a loading state to keep tracking of active requests -->
        
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Paginator from "@/components/Paginator/newPaginator";

export default {
    name: "MadeReservationsList",
    components: {
        Paginator
    },
    data() {
        return {
            toggle: true,
            mobile: true,
            url: process.env.VUE_APP_SHARE_URL
        };
    },
    methods: {
        
        ...mapActions("reservationNew", [
            "fetchMadeReservations",
        ]),
        
        buttonPago(token, idreserva) {
            return this.url + "/" + "pagos/" + idreserva + "/" + token;
        },

        changeStatus(value, fechaPago = null) {
            let status = "";
            switch (value) {
                case "initiated":
                    if (this.$i18n.locale === "es") {
                        status = "Iniciada";
                    } else {
                        status = "Started";
                    }
                    break;
                case "rejected":
                    if (this.$i18n.locale === "es") {
                        status = "Rechazada";
                    } else {
                        status = "Rejected";
                    }
                    break;
                case "accepted":
                    if (this.$i18n.locale === "es") {
                        if (fechaPago === null) {
                            status = "Aceptada";
                        } else {
                            status = "Pagado";
                        }
                    } else {
                        if (fechaPago === null) {
                            status = "Accepted";
                        } else {
                            status = "Paid Out";
                        }
                    }
                    break;
                case "canceled":
                    if (this.$i18n.locale === "es") {
                        status = "Cancelada";
                    } else {
                        status = "Canceled";
                    }
                    break;
            }
            return status;
        },

        dateFormat(value) {
            let dateArray = value.split("-");
            let mounthsEs = [
                "Enero",
                "Febrero",
                "Marzo",
                "Abril",
                "Mayo",
                "Junio",
                "Julio",
                "Agosto",
                "Septiembre",
                "Octubre",
                "Noviembre",
                "Diciembre"
            ];

            let mounthsEn = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December"
            ];

            let mounth = Number(dateArray[1] - 1);

            if (this.$i18n.locale === "en") {
                return (
                    mounthsEn[mounth] + " " + dateArray[2] + ", " + dateArray[0]
                );
            } else {
                return (
                    dateArray[2] +
                    " de " +
                    mounthsEs[mounth] +
                    " de " +
                    dateArray[0]
                );
            }
        },
        
        languageName: function(text) {
            return JSON.parse(text)[this.$i18n.locale];
        }
    },
    computed: {
        ...mapState("reservationNew", [
            "madeReservations",
        ]),
        
        reservationsData() {
            return this.madeReservations.data
        },

    },
};
</script>

<style lang="scss" scoped>
.container {

    .list {
        min-height: calc(100vh - 350px);
    }
    
    .reservation {
        border: 1px solid $hf-sec-pine;
        border-radius: $border-radius;
        margin-bottom: 20px;
    }

    h1 {
        color: $hf-sec-pine;
        font-size: 1.8em;
        //border-bottom: solid $grey-25 0.5px;
        padding-bottom: 10px;
        margin-bottom: 20px;
        text-align: right;
    }

    h4 {
        text-align: center;
        padding-top: 100px;
        color: $grey-50;
    }

    hr {
        border-top: 1px solid $hf-sec-pine;
        width: 100%;
    }
}
</style>