<template>
    <div class="loader-container">
        <div class="backdrop" :class="{'backdrop__full' : backdrop }">
            <div class="spinners" :class="{'spinners__backdrop' : backdrop }">
                
                <div>
                    <img :src="assets.logoVerticalSrc" alt="HouseFlats logo"/>
                </div>
                
                <div v-if="!error" class="mt-5">
                    <b-spinner type="grow" label="Loading..." />
                    <b-spinner type="grow" label="Loading..." />
                    <b-spinner type="grow" label="Loading..." />
                </div>
            </div>
            
            <div v-if="!message" :class="{'informations__backdrop' : backdrop }" class="informations mt-4">{{$t('loader.wait')}}</div>
            <div v-else class="informations mt-4" v-html="message" />
        </div>
    </div>
</template>

<script>

import { assets } from "@/new-constants/assets";

export default {
    name: 'Loader',
    props: {
        message: {
            type: String,
            default: "",
            required: false
        },
        error: {
            type: Boolean,
            default: false
        },
        backdrop: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            assets: assets
        }
    }
}
</script>

<style lang="scss" scoped>
.loader-container {
    margin-top: 100px;

    .spinners {
        color: $hf-sec-pine;
        margin: auto;
        z-index: 500;

        &__backdrop{
            width: 80%;
            left: 10%;
            margin-top: 30% ;
            color: #666 !important;
        }
    }

    .informations {
        color: #003D29;
        text-align: center;

        &__backdrop{
            color: #666 !important;
        }
    }

    img {
        width: 150px;
    }

    .backdrop {
        position: relative;
        width: 100%;
        height: 100%;
        background: none;
        top: 0;
        left: 0;

        &__full{
            position: fixed;
            background: #ffffffc9;    
            color: #666 !important;
        }
    }
}
</style>