import axios from 'axios';
import * as types from './types';
import apiUrls from "@/new-constants/apiUrls";

export default {

    async getBankData({ commit, dispatch }) {
        try {
            const response = await axios.get(apiUrls.bankData);
            commit(types.SET_BANK_DATA, response.data.data);
        } catch (error) {
            console.error(error);
        }
    },

    async putBankData({ commit }, data) {
        try {
            const response = await axios.put(apiUrls.bankData, data);
            commit(types.SET_BANK_DATA, response.data.data);
        } catch (error) {
            throw new Error(error);
        }
    }
};
