<template>
	<div data-view>
		<Banner />
		<Featured />
		<Testimonials />
		<Benefits />
        <!-- <Blog /> -->
	</div>
</template>

<script>
import Banner from "@/new-components/Banner/Banner.vue";
import Featured from "@/components/Featured/Featured";
import Testimonials from "@/components/Testimonials/Testimonials";
import Benefits from "@/components/Benefits/Benefits";

export default {
	components: {
		Banner,
		Featured,
		Testimonials,
		Benefits,
		// Blog,
	},
	created() {
		setTimeout(() => {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		}, 500);
		window.addEventListener("scroll", () => {
			const altura = window.innerHeight - window.pageYOffset;
			if (altura < 1) {
				this.$store.commit("setLoadLocation", true);
			}
		});
	},
};
</script>
