import axios from 'axios';
import * as types from './types';
import apiUrls from "@/new-constants/apiUrls";
import { formatDate } from "@/Utils/Utils";

export default {

    setCheckInDate ({ commit, state, dispatch }, checkInDate) {
        const date = (checkInDate == null) ? null : formatDate(checkInDate);
        commit(types.SET_CHECK_IN_DATE, date);
    },

    setCheckOutDate ({ commit, state, dispatch }, checkOutDate) {
        const date = (checkOutDate == null) ? null : formatDate(checkOutDate);
        commit(types.SET_CHECK_OUT_DATE, date);
    },

    async fetchSearchResults({ commit, state, dispatch }, searchParams) {

        const urlParams = new URLSearchParams(searchParams);
        const queryString = urlParams.toString();

        const url = `${apiUrls.searchAccommodations}?${queryString}`;

        // Fetch the search results
        const response = await axios.get(url);

        const searchResults = response.data;

        dispatch('setSearchResults', searchResults);

    },

    setSearchResults ({ commit, state, dispatch }, searchResults) {
        commit(types.SET_SEARCH_RESULTS, searchResults);
    },

    setSearchLocation ({ commit, state, dispatch }, searchLocation) {
        commit(types.SET_SEARCH_LOCATION, searchLocation);
    },

    setShowMapOnMobile ({ commit, state, dispatch }, showMapOnMobile) {
        commit(types.SET_SHOW_MAP_ON_MOBILE, showMapOnMobile);
    },

};
