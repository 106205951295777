<template>
    <div class="mobile-items mobile-only">
        
        <!-- Logo -->
        <div class="mobile-logo">
            <router-link :to="'/'">
                <img :src="assets.logoHorizontalSrc" alt="Logo"/>
            </router-link>
        </div>

        <!-- Sidebar Open/Clos button -->
        <OpenCloseButton :is-nav-open="isNavOpen" @toggle="toggleNav" />

        <!-- Backdrop -->
        <div class="sidebar-backdrop" @click="toggleNav" :class="isNavOpen ? 'show' : 'hide'" />
        
        <!-- Sidebar -->
        <Sidebar :isOpen="isNavOpen" @closeNav="closeNav" class="sidebar"/>
        
    </div>
</template>

<script>
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import OpenCloseButton from "@/components/Header/OpenCloseButton.vue";
import { assets } from "@/new-constants/assets";

export default {
    name: 'HeaderMobile',
    components: {
        OpenCloseButton,
        Sidebar
    },
    data() {
        return {
            isNavOpen: false,
            assets
        };
    },
    methods: {
        toggleNav() {
            console.log('this.isNavOpen', this.isNavOpen);
            this.isNavOpen = !this.isNavOpen;
        },
        closeNav() {
            this.isNavOpen = false;
        }
    },
}
</script>

<style lang="scss" scoped>

.mobile-items {
    align-items: center;
    z-index: 101;

    .mobile-logo {
        img {
            width: 50%;
        }
    }
}
.sidebar-backdrop {
    background-color: rgba(0, 0, 0, 0.6);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    cursor: pointer;
    opacity: 0;
    transition: ease 0.2s;
    transition-delay: 0.5s;
}

.show {
    opacity: 1 !important;
}

.hide {
    opacity: 0 !important;
    display: none;
}

</style>