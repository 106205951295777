<template>
	<div class="content">
		<h1>{{$t('admin.visits')}}</h1>

		<DashboardSearch @search="handleSearch" />

		<div v-show="loader">
			<Loader />
		</div>

		<div v-show="!loader">
			<div v-if="alojamientos.length">
				<AccItens
					:accommodations="alojamientos"
					@createicalurl="createIcalUrl"
					@createjsonurl="createJsonUrl"
				/>
				<Paginator
					:pages="paginateData.last_page"
					@setPage="reloadData"
				/>
			</div>
			<div v-if="alojamientos.length <= 0 && s == ''">
				<h4>
					{{ $t("my-accommodations.no-yet") }}
					<router-link to="/registro-alojamiento">{{
						$t("my-accommodations.here")
					}}</router-link>
				</h4>
			</div>

			<div v-if="alojamientos.length <= 0 && s != ''">
				<h4 style="width: 100%; text-align: center">
					{{ $t("my-accommodations.not-found") }}
				</h4>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@/components/Loader/Loader";
import { mapState, mapMutations, mapActions } from "vuex";
import axios from "axios";
import Paginator from "@/components/Paginator/Paginator";
import DashboardSearch from "@/components/DashboardSearch/DashboardSearch";
import AccItens from "./AccItem";

export default {
	name: "Dashboard",
	components: {
		Loader,
		Paginator,
		DashboardSearch,
		AccItens,
	},

	data() {
		return {
			url: process.env.VUE_APP_SHARE_URL,
			api: process.env.VUE_APP_API_URL,
			loader: true,
			alojamientos: [],
			paginateData: {},
			s: "",
		};
	},
	methods: {
		...mapActions(["eliminarAlojamiento", "wishList", "clonar"]),

		obtieneUrl() {
			var codigoACopiar = document.getElementById("textoACopiar");
			seleccion.selectNodeContents(codigoACopiar);
			window.getSelection().removeAllRanges();
			window.getSelection().addRange(seleccion);
			var res = document.execCommand("copy");
			window.getSelection().removeRange(seleccion);
		},
		formateaUrl(value) {
			let url = "";
			return JSON.parse((url = value.split(" ").join("-")));
		},
		getAccommodations() {
			this.loader = true;
			const urlParams = new URLSearchParams(window.location.search);
			let uri = `${this.api}admin/get-accommodations-paginated`;
			uri = `${uri}?${urlParams.toString()}`;
			this.s = urlParams.get("s");
			axios.get(uri).then((response) => {
                console.log(response.data)
				this.alojamientos = response.data.data;
				this.loader = false;
				this.paginateData = response.data;
			});
		},
		setUrl(value) {
			const urlParams = new URLSearchParams(window.location.search);
			urlParams.set("s", value);
			window.history.pushState(
				null,
				"Search",
				"/dashboard?" + urlParams.toString()
			);
		},
		reloadData(page) {
			console.log(page);
			const path = this.paginateData.first_page_url.replace(
				this.paginateData.path,
				""
			);
			let uri = `${this.api}admin/get-accommodations-paginated`;
			const urlParams = new URLSearchParams(path);
			urlParams.set("page", page);
			window.history.pushState(
				null,
				"Search",
				"/visits?" + urlParams.toString()
			);
			uri = `${uri}?${urlParams.toString()}`;
			this.setScrollTop();
			this.loader = true;
			axios.get(uri).then((response) => {
				this.alojamientos = response.data.data;
				this.loader = false;
				this.paginateData = response.data;
				this.setScrollTop();
			});
		},
		handleSearch(e) {
			console.log(e);
			const urlParams = new URLSearchParams(window.location.search);
			urlParams.delete("page");
			urlParams.set("s", e);
			let uri = `${this.api}admin/get-accommodations-paginated`;
			window.history.pushState(
				null,
				"Search",
				"/visits?" + urlParams.toString()
			);
			uri = `${uri}?${urlParams.toString()}`;
			this.loader = true;
			axios.get(uri).then((response) => {
				this.alojamientos = response.data.data;
				this.loader = false;
				this.paginateData = response.data;
				this.setScrollTop();
				this.$refs["dashboardInput"].focus();
			});
		},
		setScrollTop() {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		},
		createIcalUrl(id) {
			let api = this.api.replace("api/v1/", "");
			let uri = `${api}ical`;
			axios
				.post(uri, { id })
				.then((response) => {
					this.alojamientos.map((item, index) => {
						if (parseInt(item.idalojamiento) === parseInt(id)) {
							this.alojamientos[index].get_url_ical = {
								url_ical: response.data.url,
							};
						}
					});
				})
				.catch(() => {
					alert("Algo mal pasó.");
				});
        },        
        createJsonUrl(id) {
			let api = this.api.replace("api/v1/", "");
			let uri = `${api}json`;
			axios
				.post(uri)
				.then((response) => {
					window.location.reload();
				})
				.catch(() => {
					alert("Algo mal pasó.");
				});
        }, 
	},
	computed: {
		...mapState(["user", "heart"]),
	},
	created() {
		this.getAccommodations();
		window.scrollTo(0, 0);
	},
};
</script>


<style scoped lang="scss">
.content {
	padding: 0 40px;
	h1 {
		color: $hf-sec-pine;
		font-size: 1.8em;
		//border-bottom: solid rgb(231, 231, 231) 0.5px;
		padding-bottom: 10px;
		margin-bottom: 20px;
		text-align: right;
	}

	&__actions {
		display: flex;
		justify-content: space-between;
		padding: 5px 0;
		div * {
			margin: 0 4px;
		}
	}

	.availability-price {
		padding: 20px 0;
	}

	.action-item {
		cursor: pointer;
	}
}

.loader {
	.loader-img {
		width: 150px;
		margin: auto;
		display: block;
	}

	.loader-text {
		color: $hf-sec-pine;
		text-align: center;
		font-size: 1.5em;
	}
}
</style>

<style lang="scss" scoped>
.highlight-content {
	display: flex;
	width: 100%;
	min-height: 80vh;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	.highlight-title {
		width: 90%;
		font-size: 2em;
		padding: 20px;
		border-bottom: 1px solid #e3e3e3;
		margin: 20px;
	}

	.main-content {
		width: 80%;
		display: flex;
		align-items: center;
		flex-direction: column;

		.component {
			display: flex;
			width: 100%;
			margin-bottom: 20px;

			input {
				margin: 0 10px;
			}
		}
	}
}
</style>