
<template>

    <!-- Search box -->
    <form class="searchbox-home">

        <!-- Title and close button-->
        <div>

            <!-- Title -->
            <h1 class="search-box-title">{{ title | capitalFirst }}</h1>

        </div>

        <!-- Location and date range -->
        <div class='search-fields-group'>

            <!-- Location field -->
            <div class='location-container'>
                <multiselect
                    v-model="direccion"
                    track-by="comuna"
                    :placeholder="$t('search.select-city')"
                    class="location-multiselect"
                    :open-direction="'bottom'"
                    :options="locationsList"
                    :custom-label="customLabel"
                    :searchable="true"
                    :allow-empty="false"
                    selectLabel=""
                    deselectLabel=""
                    selectedLabel=""
                    @open="clearErrors"
                >
                    <!-- No result message -->
                    <template slot="noResult" >
                        <router-link :to="{ name: 'contact' }" class="no-results">
                            {{ $t('search.no-result') }}
                        </router-link>
                    </template>

                    <!-- Multiselect closed placeholder -->
                    <template slot="singleLabel" slot-scope="{ option }">
                        <span><i class="fas fa-map-marker-alt mr-2"></i></span>
                        <span v-if="option.country && option.comuna"> {{ option.country }} | <strong>{{ option.comuna }}</strong></span>
                        <span v-else >{{ $t('search.select-city') }}</span>
                    </template>

                </multiselect>

                <!-- Validation message -->
                <div v-if="$v.direccion.$error" class="col-12 text-danger" >
                    <span>{{$t('search.location-validation')}}</span>
                </div>
            </div>

            <DatePicker showAnyDateCheckbox class="datepicker-container"/>

            <!-- Search button -->
            <Button class="new-btn btn-search shadow" variant="primary" @click.prevent="search()">
                <img :src="assets.searchIconSrc" class="mr-2" alt="Location Icon" />
                {{ $t('search.search') }}
            </Button>

        </div>

    </form>

</template>

<script>
import { required } from "vuelidate/lib/validators";
import Multiselect from 'vue-multiselect'
import { mapState, mapActions } from "vuex";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";
import * as moment from "moment";
import { locationsList } from "@/new-constants/locationsList";
import { priceConverter } from "@/Utils/Utils";
import DatePicker from "@/new-components/DatePicker/DatePicker.vue";
import { normalizeText } from "@/Utils/Utils";
import Button from "@/components/Ui/Button.vue";
import { assets } from '@/new-constants/assets';

export default {
    name: "SearchBanner",
    components: {
        Button,
        // VueSlider,
        Multiselect,
        DatePicker
    },
    data() {
        return {
            locationsList: locationsList,
            // direccion_error: false,
            
            
            // REMOVE
            // categorias,
            // tipo_alojamiento: ["entero", "privado", "compartido"],
            // categoria_selected: [],
            // dates_required: false,
            // date: 'null',
            // nro_huespedes: 0,
            // rooms_number: null,
            // precio_max: null,
            // precio_min: null,
            // sliderScaleMin: 0,
            // placeholder: "Elige la localidad",
            // filtros_opcionales_selected: [],
            // filters_piece_selected: [],
            // show_more_filters: false,
            // currency: "$",
            priceRange: [],
            // full_name: "",
            direccion: {},
            // from_address: {},
            // filtros_booleanos: [],
            // observarPicker: [],
            // dailyMode: 1,
            // toggle_filters: {
            //     state: true,
            //     value: "-"
            // },
            // currency_code: "clp",
            // options: [],
            // optionsCategory: categorias,
            // selectedType: [],
            selectedCategories: [],
            // northeast:"",
            // southwest:"",
            // colorVariable: '#495057',
            // selectedCity: "",
            // showLocationDatesModal: false,
            // useMonthlyPriceRange: true
            assets,

        };
    },
    computed: {

        ...mapState('exchange', [
            "visitorCurrency",
        ]),

        ...mapState('search', [
            "checkInDate",
            "checkOutDate",
            "searchLocation",
        ]),

        title() {
            // Check if the current component is 'SearchQuery'
            if (this.$route.name === 'search-query') {
                const searchQuery = this.$route.params.search_query;

                const searchQueryArray = searchQuery.split(/[_]/);
                return searchQueryArray[0].replace(/-/g, ' ') + ' ' + this.searchLocation.long_name;
            }

            // If it's not the 'SearchQuery' component, use the translation file
            return this.$t('search.search-accommodation');
        },

        // sliderInterval() {
        //     let divisor = this.useMonthlyPriceRange ? 1 : 10;
        //
        //     switch (this.visitorCurrency) {
        //         case "GBP":
        //         case "EUR":
        //             return 100 / divisor;
        //         case "CLP":
        //             return 100000 / divisor;
        //         case "USD":
        //         default:
        //             return 100 / divisor;
        //     }
        // },
        //
        // sliderScaleMax() {
        //     let divisor = this.useMonthlyPriceRange ? 1 : 10;
        //
        //     switch (this.visitorCurrency) {
        //         case "GBP":
        //         case "EUR":
        //             return 3000 / divisor;
        //         case "CLP":
        //             return 3000000 / divisor;
        //         case "USD":
        //         default:
        //             return 3000 / divisor;
        //     }
        // },
        //
        // filters_piece_list() {
        //     return this.$t("search.filter_list");
        // },
        //
        // appliedFiltersCount() {
        //     const count =  (this.nro_huespedes > 0 ? 1 : 0) +
        //         this.filtros_opcionales_selected.length +
        //         this.filters_piece_selected.length +
        //         this.selectedType.length +
        //         this.selectedCategories.length +
        //         (this.rooms_number != null ? 1 : 0)
        //     ;
        //
        //     this.setAppliedFiltersCount(count);
        //
        //     return count;
        // },

        // getSliderText() {
        //     if (this.checkInDate !== 'null' && this.checkOutDate !== 'null') {
        //         const a = moment(this.checkInDate);
        //         const b = moment(this.checkOutDate);
        //         const duration = moment.duration(b.diff(a));
        //         if (duration.asDays() < 30) {
        //             this.useMonthlyPriceRange = false;
        //             return "search.day-price";
        //         }
        //     }
        //     this.useMonthlyPriceRange = true;
        //     return "search.monthly-price";
        // },

    },
    watch: {

        showRoomsFilter(newValue) {
            if (!newValue) {
                this.rooms_number = null;
            }
        },

        title: {
            immediate: true,
            handler(newVal) {
                document.title = this.$options.filters.capitalWords(newVal);
            }
        },

        // useMonthlyPriceRange(newValue, oldValue) {
        //     this.resetPriceRange();
        //     this.updateAppliedFiltersCount();
        // },

        // Reset the price rage when the currency is switched
        // visitorCurrency(newValue, oldValue) {
        //     this.resetPriceRange();
        // },

        // If the user select a new location, set the location in the store
        direccion(newDireccion) {
            if (newDireccion && newDireccion.comuna) {
                this.setSearchLocation(newDireccion);
            }
        },

        searchLocation: {
            immediate: true,
            handler(newLocation) {
                if (newLocation && newLocation.comuna && (this.$route.name === 'search' || this.$route.name === 'search-query')) {
                    this.direccion = newLocation;
                }
            }
        },

        // Refactor this
        // selectedType(newValue, oldValue) {
        //
        //     let optionsCategoryList = [];
        //
        //     newValue.forEach((item) => {
        //         categorias.forEach((opItem) => {
        //             if(opItem.owner.indexOf(item.value) !== -1) {
        //                 let s = optionsCategoryList.find((cl) => opItem.value === cl.value)
        //                 if(s === undefined) {
        //                     optionsCategoryList.push(opItem)
        //                 }
        //             }
        //         })
        //     })
        //
        //     const availables = []
        //     optionsCategoryList.forEach((item) => {
        //         let s = this.selectedCategories.find((f) => f.name === item.name);
        //         if(s !== undefined) {
        //             availables.push(s)
        //         }
        //     })
        //     this.selectedCategories = availables;
        //     this.optionsCategory = optionsCategoryList;
        //
        //     this.updateAppliedFiltersCount();
        // },

        // nro_huespedes(newValue, oldValue) {
        //     if (newValue <= 0 && newValue != "") {
        //         this.nro_huespedes = 1;
        //     }
        //     this.updateAppliedFiltersCount();
        // },
        //
        // filters_piece_selected(newValue, oldValue) {
        //     localStorage.setItem("filter_piece", newValue);
        //     this.updateAppliedFiltersCount();
        // },
        //
        // filtros_opcionales_selected(newValue, oldValue) {
        //     localStorage.setItem("filter_piece_optional", newValue);
        //     this.updateAppliedFiltersCount();
        // },

        // tipo_alojamiento(newValue, oldValue) {
        //     localStorage.setItem("tipo_alojamiento", newValue);
        //     this.updateAppliedFiltersCount();
        // },
    },
    created() {
        // this.resetPriceRange();

        // Set the select to the search location
        // if (this.$route.name == 'search' || this.$route.name === 'search-query') {
        //     this.direccion = this.searchLocation;
        // }

        // Refactor this
        // this.translateTypes();
        // let options = [
        //     {
        //         name: this.$t('search.types.entero'),
        //         value: "entero"
        //     },
        //     {
        //         name: this.$t('search.types.privado'),
        //         value: "privado"
        //     },
        //     {
        //         name: this.$t('search.types.compartido'),
        //         value: "compartido"
        //     }
        // ]

        // Refactor this
        // this.options = options
        // this.selectedType = []

        // Refactor this
        // if (localStorage.getItem("filter_piece")) {
        //     this.filters_piece_selected = [];
        //     this.filters_piece_selected = localStorage
        //         .getItem("filter_piece")
        //         .trim()
        //         .split(",");
        // }

        // Refactor this
        // if (localStorage.getItem("filter_piece_optional")) {
        //     this.filtros_opcionales_selected = [];
        //     this.filtros_opcionales_selected = localStorage
        //         .getItem("filter_piece_optional")
        //         .trim()
        //         .split(",");
        // }
    },
    methods: {

        ...mapActions('search', [
            'setSearchLocation'
        ]),

        ...mapActions('filter', [
            'setAppliedFiltersCount',
        ]),

        customLabel (direccion) {
            return `${direccion.country} | ${direccion.comuna}`
        },

        search() {
            
            this.$v.$touch();
            
            if (!this.$v.$invalid) {

                this.$v.$touch(); // validate form

                // this.direccion_error = false;
                //
                // if (this.direccion.long_name === undefined || this.direccion.long_name === null) {
                //     this.direccion_error = true;
                // }
                //
                // if (this.direccion_error) {
                //     return;
                // }

                this.dailyMode = 0;
                if (this.checkInDate !== null && this.checkOutDate !== null) {
                    const init = moment(this.checkInDate);
                    const end = moment(this.checkOutDate);
                    const duration = moment.duration(end.diff(init));
                    if (duration.asDays() < 30) {
                        this.dailyMode = 1;
                    }
                }

                // Convert price range to USD because the api make the search in USD
                const fromPrice = priceConverter({ 'amount': this.priceRange[0], 'currency': this.visitorCurrency }, 'USD').amount;
                let toPrice = priceConverter({ 'amount': this.priceRange[1], 'currency': this.visitorCurrency }, 'USD').amount;

                // If the max price selected is the same as the max range, set it to 0 to avoid filtering
                toPrice = toPrice === this.sliderScaleMax ? 0 : toPrice;

                var params = {
                    latitud: this.direccion.latitud,
                    longitud: this.direccion.longitud,

                    northeast: `${this.direccion.northeast}`,
                    southwest: `${this.direccion.southwest}`,
                    // northeast: {
                    //     lat: this.direccion.northeast.lat,
                    //     lng: this.direccion.northeast.lng
                    // },
                    // southwest: {
                    //     lat: this.direccion.southwest.lat,
                    //     lng: this.direccion.southwest.lng
                    // },

                    country: this.direccion.country,
                    comuna: this.direccion.comuna,
                    long_name: this.direccion.comuna,
                    type_route: this.direccion.type_route,
                    fecha_inicio: this.checkInDate,
                    fecha_termino: this.checkOutDate,
                    precio_min: fromPrice,
                    precio_max: toPrice,
                    // tipo_alojamiento: [],
                    numero_huespedes: this.nro_huespedes,
                    rooms_number: this.rooms_number,
                    direccion_completa: `${this.direccion.comuna},${this.direccion.country}`,
                    page: 1,
                    polygon: this.direccion.polygon
                };

                // Add the selected types to the parameters
                // if (this.selectedType.length > 0) {
                //     params["tipo_alojamiento"] = [];
                //     this.selectedType.forEach((item) => {
                //         params["tipo_alojamiento"].push(item.value)
                //     })
                // }

                // Add the selected categories to the parameters
                if (this.selectedCategories.length > 0) {
                    params["categoria"] = [];
                    this.selectedCategories.forEach((item) => {
                        params["categoria"].push(item.value)
                    })
                }

                // Add the optional filters to the parameters
                if (this.filtros_opcionales_selected) {
                    for (var filtro in this.filtros_opcionales_selected) {
                        params[this.filtros_opcionales_selected[filtro]] = 1;
                    }
                }

                if (this.filters_piece_selected) {
                    for (var filtro in this.filters_piece_selected) {
                        params[this.filters_piece_selected[filtro]] = 1;
                    }
                }

                // Close search menu
                this.$emit('closeSearchMenu');
                this.show_more_filters = false;

                this.$router.push({ name: 'search', query: params });

                this.showLocationDatesModal = false;

            }
        },

        clearErrors() {
            this.$v.$reset();
        }

        // clearFilters() {
        //     this.filters_piece_selected = [];
        //     this.selectedType = [];
        //     this.selectedCategories = [];
        //     this.nro_huespedes = 1;
        //     this.rooms_number = null;
        //     // this.resetPriceRange();
        //
        //     // Close the more filter menu
        //     this.show_more_filters = false;
        // },
        //
        // resetPriceRange() {
        //     // Set the initial values for the price range filter
        //     this.priceRange[0] = this.sliderScaleMin;
        //     this.priceRange[1] = this.sliderScaleMax;
        // },
        //
        // translateTypes(){
        //
        //     this.options.forEach((item) => {
        //         item.name = this.$t(`search.types.${item.value}`)
        //     })
        //
        //     this.selectedType.forEach((item) => {
        //         item.name = this.$t(`search.types.${item.value}`)
        //     })
        //
        //     this.selectedCategories.forEach((item) => {
        //         item.name = this.$t(item.translated)
        //     })
        //
        //     // this.optionsCategory.forEach((item) => {
        //     //     item.name = this.$t(item.translated)
        //     // })
        // },
        //
        // toggle_filter() {
        //     if (this.toggle_filters.state) {
        //         this.toggle_filters.state = false;
        //         this.toggle_filters.value = "+";
        //     } else {
        //         this.toggle_filters.state = true;
        //         this.toggle_filters.value = "-";
        //     }
        // },
        //
        // getSelectedFilters(selected, list) {
        //     let filters = [];
        //
        //     selected.forEach(value => {
        //         let filter_name = "";
        //
        //         Object.keys(list).forEach((name, index) => {
        //             if (name === value) {
        //                 filter_name = list[name];
        //
        //                 filter_name =
        //                     filter_name.charAt(0).toUpperCase() +
        //                     filter_name.slice(1);
        //
        //                 filters.push(filter_name);
        //             }
        //         });
        //     });
        //
        //     return filters.join(" - ");
        // },
        //
        // getTextPiece(key) {
        //     return this.filters_piece_list[key];
        // },
        //
        //
        // closeSearch() {
        //     this.$emit("closeSearch");
        // },
        // toogleOptionalFilters() {
        //     this.show_more_filters = !this.show_more_filters;
        // },
        //
        // sliderTooltip(priceRange) {
        //     return priceRange[0] + " " + this.visitorCurrency + " - " + priceRange[1] + " " + this.visitorCurrency;
        // },
        //
        // updateAppliedFiltersCount() {
        //     const count = this.appliedFiltersCount;
        //     this.setAppliedFiltersCount(count);
        // }
    },
    validations: {
        direccion: { required }
    }
};
</script>

<style src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>

@import "@/new-assets/styles";

.searchbox-home {
 
    width: 72%;

    .search-fields-group {
        width: 95%;
        margin-top: 30px;
    }
    
    .search-box-title {
        font-size: 1.9em;
    }
    
    @media (min-width: $md) {
        .search-box-title {
            font-size: 2.5em;
        }
    }

    .datepicker-container {
        margin-top: 5px;
    }

    .btn-search {
        width: 100%;
    }

}

// Location select
::v-deep .location-multiselect {
    
    // Mouse over locations highlight
    .multiselect__option--highlight {
        background: $grey-10;
        color: $hf-sec-carbon;
    }
    
    // Select border, radius, etc
    .multiselect__tags {
        height: 100%;
        padding: 10px 10px 4px 25px;
        border-radius: $border-radius-new;
        border: 1px solid $grey-10;
    }
    
    // Select open arrow
    .multiselect__select {
        padding: 9px 5px 0 0;
        z-index: 0;
    }

    // No result Multiselect style
    .no-results {
        color: $hf-sec-carbon;
        text-decoration: underline;

        &:hover {
            text-decoration: underline !important;
        }
    }
}


</style>