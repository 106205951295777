<template>
    <header class="header-container">
        
        <!-- eliminar-Navbar Section -->
        <div class="navbar">
            <div class="navbar-container container-fluid">

                <!-- Mobile View -->
                <HeaderMobile/>

                <!-- Desktop View -->
                <HeaderDesktop/>
                
            </div>
        </div>
        
    </header>
</template>

<script>
import HeaderMobile from "@/components/Header/HeaderMobile.vue";
import HeaderDesktop from "@/components/Header/HeaderDesktop.vue";

export default {
    name: "Header",
    components: {
        HeaderDesktop,
        HeaderMobile,
        
    },
};
</script>

<style lang="scss" scoped>
.header-container {
    position: fixed;
    width: 100%;
    z-index: 200;
    top: 0;
    background: #fff;
    border-bottom: 1px solid $grey-25;
    //box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.3);

    .navbar {
        height: $desktop-header-height;
        
        .navbar-container {
            padding: 0;

        }
    }
}
</style>