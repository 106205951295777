<template>
	<div>
        <Header />
        <div class="main-content row mx-0">
            <DashboardSidebar v-if="showDashboard" class="col-md-2 mt-3 desktop-only"/>
            <div class="col-12 px-0" :class="{'col-md-10 mt-4': showDashboard}">
                <slot />
            </div>
		</div>
		<Footer />
	</div>
</template>

<script>
import Footer from "@/components/Footer/Footer";
import Header from "@/components/Header/Header";
import DashboardSidebar from "@/components/Sidebar/Dashboard.vue";

export default {
	components: {
		Footer,
        Header,
        DashboardSidebar
	},
    props: {
        showDashboard: {
            type: Boolean,
            default: false
        }
    },
};
</script>

<style lang="scss" scoped>
.main-content {
	min-height: calc(100vh - $desktop-header-height);
	margin-top: $desktop-header-height;

	@media (max-width: $md) {
		min-height: calc(100vh - $mobile-header-height);
        margin-top: $mobile-header-height;
	}
    
}
</style>