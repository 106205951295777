<template>
    <div class="container">
        <h1>{{$t('accommodation.block-dates')}}</h1>
        
        <!-- ical link form field -->
        <form class="d-flex justify-content-between mb-4">
            <input
                type="text"
                class="form-control flex-grow-1 mr-2"
                id="ical-link"
                v-model="formIcalUrl"
            />
            <!-- inline save button -->
            <button
                class="btn btn-primary mr-2"
                @click.prevent="handleIcalUrlSubmit"
                :disabled="formIcalUrl === icalUrl"
            >
                {{$t('block-dates.save')}}
            </button>

            <!-- inline run sync button -->
            <button
                class="btn btn-primary"
                @click.prevent="handleSync()"
                :disabled="formIcalUrl !== icalUrl"
            >
                {{$t('block-dates.run-sync')}}
            </button>
            
        </form>

        <DatePicker />

        <div class="btn-save mb-5">
            <router-link to="/dashboard">
                <button class="btn mr-3"> {{$t('simulator.back')}} </button>
            </router-link>
            <button
                @click="saveRestriction"
                class="btn"
                type="submit"
                :disabled="dates.fecha_inicio === null || dates.fecha_termino === null || loading"
            >
                <b-spinner style="width:20px; height:20px" v-if="loading"/>
                {{$t('bank.save')}}
            </button>
        </div>
        
        <div v-for="date in block_dates" :key="date.idrestriccion" class="row date-block" >
            <div class="col-8">
                {{`${$t('block-dates.from')}: ${date.fecha_inicio} - ${$t('block-dates.to')}: ${date.fecha_termino}`}}
            </div>
            <div class="col-2">
                <span>{{ date.created_with }}</span>
            </div>
            <div class="col-2 text-left text-sm-right">
                <a
                    href="javascript:void(0)"
                    @click="eraseRestrict(date.idrestriccion)"
                    class="btn-delete"
                >
                    <i class="far fa-trash-alt" />
                </a>
            </div>
            <hr/>
        </div>

        <b-modal id="info-modal" size="lg" ref="info-modal" :title="$t('block-dates.title-modal')" hide-footer>
            <p class="my-4">{{$t('block-dates.error-info')}}</p>
            <hr>
            <div class="row">
                <div class="col-12 text-right">
                    <button class="mx-2 btn btn-outline-primary" @click="closeModal" id="block-dates-ok-button">Ok</button>                    
                </div>
            </div>
        </b-modal>
        
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import format from "date-fns/format";
import DatePicker from "@/new-components/DatePicker/DatePicker.vue";

export default {
    name: "BlockDates",
    components: {
        DatePicker
    },
    data() {
        return {
            // dates: {
            //     fecha_inicio: null,
            //     fecha_termino: null,                
            // },
            loading: false,
            formIcalUrl: ""
        }
    },
    methods: {
        
        ...mapActions("restriction", [
            "postIcalUrl",
            "fetchIcalUrl",
            "syncWithExternalIcal"
        ]),
        
        ...mapActions([
            "createRestriction",
            "getRestriction",
            "deleteRestriction"
        ]),

        async handleSync() {
            
            const response = await this.syncWithExternalIcal(this.accommodationId);

            const message = response ? this.$t('block-dates.sync-success') : this.$t('block-dates.sync-fail');

            this.refreshRestrictionsTable();

            // Show  message
            let toast = this.$toasted.show(message, {
                theme: "toasted-primary",
                position: "bottom-right",
                duration : 5000,
                className: "toasted"
            });
        },
        
        async handleIcalUrlSubmit() {

            const response = await this.postIcalUrl({
                icalUrl: this.formIcalUrl,
                accommodationId: this.accommodationId
            });

            const message = response ? this.$t('block-dates.url-saved') : this.$t('block-dates.url-not-saved');
            
            // Show success message
            let toast = this.$toasted.show(message, {
                theme: "toasted-primary",
                position: "bottom-right",
                duration : 5000,
                className: "toasted"
            });
            
        },
        
        saveRestriction() {
            // format dates to yyyy-MM-dd
            this.dates.fecha_inicio = this.checkInDate;
            this.dates.fecha_termino = this.checkOutDate;
            
            this.loading = true;
            this.createRestriction(this.dates).then((response) => {
                this.refreshRestrictionsTable();
                this.loading = false;
                this.$refs.calendar.clearSelection();
            }).catch((error) => {
                if(error.response.status === 400) {
                    this.$refs['info-modal'].show()
                    this.loading = false;
                    this.$refs.calendar.clearSelection();
                } else {
                    this.$router.push('/dashboard');
                }
            })
            
        },
        
        refreshRestrictionsTable() {
            this.getRestriction().then((resp) => {
                this.$store.commit('setBlock_dates', resp.data);
            });
        },
        
        eraseRestrict(id) {
            this.deleteRestriction(id).then((resp) => {
                this.$store.commit('setBlock_dates', resp.data.restrictions);
            })
        },
        closeModal() {
            this.$refs['info-modal'].hide()
        }
    },
    computed: {
        
        ...mapState('search', [
            "checkInDate",
            "checkOutDate",
        ]),
        
        ...mapState("restriction", [
            "icalUrl"
        ]),
        
        ...mapState(["loader", "blockdates", "block_dates"]),
        
        accommodationId() {
            // Get id from url
            return this.$route.params.id;
        },
        
        dates() {
            return {
                fecha_inicio: this.checkInDate,
                fecha_termino: this.checkOutDate
            }
        },
    },
    created() {
        // Get the icalUrl from the server, save it to the store, and set it in the form
        this.fetchIcalUrl(this.accommodationId)
            .then(() => {
                this.formIcalUrl = this.icalUrl;
            });
        
        this.getRestriction()
        .then((response) => {
            this.$store.commit('setBlock_dates', response.data);
        }).catch((error) => {
            this.$router.push('/dashboard');
        });
    }
};
</script>

<style lang="scss" scoped>

    .btn-outline-primary {
        background-color: #FFF;
        border-color: $hf-sec-pine !important;
        color: $hf-sec-pine;
        &:hover{
            color: #fff;
            background-color: $hf-sec-pine;
            border-color: $hf-sec-pine;
        }
    }
.container {
    
    .btn {
        background-color: $hf-primary;
        border: none;
    }
    
    h1 {
        color: $hf-sec-pine;
        font-size: 1.8em;
        //border-bottom: solid rgb(231, 231, 231) 0.5px;
        padding-bottom: 10px;
        margin-bottom: 20px;
        text-align: right;
    }

    .btn-save {
        margin-top: 10px;
        text-align: right;
        button {
            color: white;
            background-color: $hf-sec-pine;
        }
    }

    .btn-delete {
        color: red;
        font-size: 1.1em;
    }

    .date-block{
        font-size: .8em;
    }

    hr {
        width: 90%;
    }
}

@media (min-width: 767px) {
    hr{
        width: 100% !important;
    }
}
</style>

<style>

.datepicker {
    z-index: 1021 !important;
}

// Next month button
.next--mobile {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    bottom: 0;
    background: $hf-sec-pine;
    height: $see-more-button-height;
    color: #FFFFFF;
    font-size: 1.2rem;
    font-style: italic;
    z-index: 1021 !important;
}

</style>


