<template>
    <div class="sidebar-container" :class="{ active: isOpen }">

        <div class="menu" @click="emitClose">

            <router-link v-if="isLoggedIn && user.is_admin === 1" :to="{ name: 'admin' }" class="menu__highlight">{{
                    $t('main-menu.admin')
                }}
            </router-link>
            
            <router-link v-if="!isLoggedIn" :to="{ name: 'login' }" class="menu__highlight">{{
                    $t('main-menu.sign-in')
                }}
            </router-link>

            <router-link v-if="!isLoggedIn" :to="{ name: 'register', params: { step: 1 } }" class="menu__highlight">{{
                    $t('main-menu.sign-up')
                }}
            </router-link>

            <router-link :to="{ name: 'contact' }" class="menu__highlight">{{ $t('main-menu.contact') }}</router-link>

            <router-link :to="{ name: 'home' }" class="menu__item">{{ $t('main-menu.home') }}</router-link>
            
            <router-link v-if="isLoggedIn" :to="{ name: 'my-accommodations' }" class="menu__item">{{
                    $t('main-menu.my-profile')
                }}
            </router-link>

            <router-link v-if="isLoggedIn" :to="{ name: 'reservations-made' }" class="menu__item">{{
                    $t('main-menu.reservations-made')
                }}
            </router-link>

            <router-link v-if="isLoggedIn" :to="{ name: 'reservations-received' }" class="menu__item">
                {{ $t('main-menu.reservations-received') }}
            </router-link>

            <router-link v-if="isLoggedIn" :to="{ name: 'favoritos' }" class="menu__item">{{ $t('main-menu.my-favorites') }}</router-link>

            <router-link v-if="isLoggedIn" :to="{ name: 'editar-perfil' }" class="menu__item">{{
                    $t('main-menu.edit-profile')
                }}
            </router-link>

            <router-link v-if="isLoggedIn" :to="{ name: 'Cambiar Password' }" class="menu__item">{{
                    $t('main-menu.change-password')
                }}
            </router-link>
            
            <router-link v-if="isLoggedIn" :to="{ name: 'Datos bancarios' }" class="menu__item">{{
                    $t('main-menu.bank-information')
                }}
            </router-link>
            
            <router-link v-if="isLoggedIn" :to="{ name: 'ical' }" class="menu__item">{{ $t('main-menu.icalendar') }}</router-link>

            <router-link :to="{ name: 'about' }" class="menu__item">{{ $t('main-menu.about') }}</router-link>
            
            <a href="https://blog.houseandflats.com/" class="menu__item">Blog</a>
            
            <a class="menu__item" v-if="isLoggedIn" @click="logout">
                {{ $t('dashboard-side-menu.sign-out') }}
            </a>
            
            <div class="switchers">
                <CurrencySwitcher/>
                <LanguageSwitcher/>
            </div>
            
        </div>

    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import menuData from "./menu-data.json";
import {mapGetters} from "vuex";
import CurrencySwitcher from "@/components/Header/CurrencySwitcher.vue";
import LanguageSwitcher from "@/components/Header/LanguageSwitcher.vue";

export default {
    name: "Sidebar",
    components: {LanguageSwitcher, CurrencySwitcher},
    props: [
        "isOpen"
    ],
    data() {
        return {
            menuData,
        };
    },
    computed: {
        ...mapState(["user"]),
        ...mapGetters(["isLoggedIn"]),
    },
    methods: {
        ...mapActions(["logout"]),
        emitClose() {
            this.$emit('closeNav');
        },
    },
};
</script>


<style lang="scss" scoped>

.sidebar-container {
    position: fixed;
    overflow: scroll;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
    z-index: 100;
    transform: translateX(100%); /* Oculto por defecto */
    transition: transform 0.3s ease; /* Transición suave */

    &.active {
        transform: translateX(0); /* Mueve el menú a su posición visible */
    }
}

.menu {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: initial;
    font-size: 24px;
    gap: 20px;

    &__item {
        color: $hf-sec-carbon;
        text-decoration: none;
        font-weight: bold;
    }

    &__highlight {
        color: $hf-sec-pine;
        text-decoration: none;
        font-weight: bold;
    }
    
    
    
    
}

.switchers {
    display: flex;
    gap: 20px;
    margin-top: 10px;
}

</style>
