<template>
    <b-modal
        v-model="isVisible"
        size="lg"
        hide-footer
        hide-header
        centered
        class="d-flex align-items-center justify-content-center"
        content-class="content"
        body-class="body"
    >
        <p class="message">{{ message }}</p>
        
        <div class="alert-button-div">
            <Button @click="hideAlert" class="new-btn" variant="primary">{{ okButtonText }}</Button>    
        </div>
        
        
    </b-modal>
</template>

<script>
import Button from "@/components/Ui/Button.vue";

export default {
    name: 'AlertModal',
    components: { Button },
    props: {
        message: {
            type: String,
            required: true
        },
        okText: {
            type: String,
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isVisible: this.visible
        };
    },
    computed: {
        okButtonText() {
            return this.okText || this.$t('alert.ok');
        }
    },
    watch: {
        visible(val) {
            this.isVisible = val;
        }
    },
    methods: {
        hideAlert() {
            this.isVisible = false;
            this.$emit('hideAlert');
        }
    }
};
</script>

<style lang="scss" scoped>

@import "@/new-assets/styles";

::v-deep .content {
    text-align: center;
    border-radius: 30px;
}

::v-deep .body {
    padding: 32px;
}

.message {
    padding-top: 34px;
    margin-bottom: 0;
    font-size: 20px;
}

.alert-button-div {
    display: flex;
    justify-content: center;
}

.btn {
    margin-top: 30px;
}
    
</style>
