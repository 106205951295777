<template>
    <div class="container">
        <Owl
            class="owl"
            v-if="accommodation.length"
            :responsive="{0:{items:1},600:{items:2},1000:{items:4}}"
            :autoplay="true"
            :autoplayTimeout=3000
            :nav="false"
            :navText="[$t('search-query.previous'), $t('search-query.next')]"
            ref="owl"
        >
            <div 
                :key="accomodation.idalojamiento" 
                v-for="accomodation in accommodation"
                class="item-inner"
            >
                <AccommodationItem
                    :fav="accomodation.alojamiento_idalojamiento"
                    :id="accomodation.idalojamiento"
                    :nombre="accomodation.nombre"
                    :direccion="accomodation.direccion"
                    :accommodation="accomodation"
                    :from="'home'"
                />
            </div>
        </Owl>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Owl from "vue-owl-carousel";
import AccommodationItem from "@/components/AccommodationItem/AccommodationItem";
import {locationsList} from "@/new-constants/locationsList";
import {hreflangKeywords} from "@/lang/hreflangKeywords";
import {basePhrases} from "@/lang/basePhrases";
import { setDescriptionHeader } from "@/Utils/seo";
import {getLocationFromSearchQuery, normalizeText} from "@/Utils/Utils";

export default {
    name: "SearchQueryResults",
    data() {
        return {
            // loading: true,
        };
    },
    components: {
        Owl,
        AccommodationItem
    },
    methods: {
        ...mapActions('search', [
            'fetchSearchResults',
            'setSearchLocation',
        ]),
        ...mapActions('searchQuery', [
            'setLocationDescription',
            'setLocationImage',
            'setSearchQueryLocation'
        ]),
    },
    computed: {

        ...mapState('search', [
            'searchResults',
            'searchLocation'
        ]),

        accommodation() {
            return this.searchResults.data;
        },

        // Get the location from the search query
        location() {

            const queryLocation = getLocationFromSearchQuery(this.$route.params.search_query);

            // if locations is not {}
            if (queryLocation) {
                this.setSearchQueryLocation(queryLocation);
            }
            
            return queryLocation;
        },

        queryParams() {

            let params = {
                latitud: this.location.latitud,
                longitud: this.location.longitud,
                northeast: `${this.location.northeast}`,
                southwest: `${this.location.southwest}`,
                country: this.location.country,
                comuna: this.location.long_name,
                long_name: this.location.long_name,
                type_route: undefined,
                precio_min: 0,
                precio_max: 0,
                tipo_alojamiento: '',
                numero_huespedes: 1,
                direccion_completa: `${this.location.long_name},${this.location.country}`,
                page: 1,
                polygon: this.location.polygon,
                limit: 12,
            };
            
            return params;
        }
    },
    created() {
        this.fetchSearchResults(this.queryParams);
        this.setSearchLocation(this.queryParams);
        
    },
    watch: {
        'location': {
            immediate: true,
            handler(location) {
                // Get the current language from the i18n system
                const currentLang = this.$i18n.locale;

                // Get accommodation name based on the current language, default to 'en' if not found
                let accommodationName = hreflangKeywords[currentLang] || hreflangKeywords['es'];
                
                // Replace - by " "
                accommodationName = accommodationName.replace(/-/g, ' ');

                // Get the base phrase according to the current language, default to 'en' if not found
                const basePhrase = basePhrases[currentLang] || basePhrases['es'];
                
                // Replace placeholders for accommodation name and location in the base phrase
                const description = basePhrase
                    .replace('$accommodationName', accommodationName)
                    .replace('$location', location.long_name);

                // Set the location description and image
                this.setLocationDescription(description);
                const image = location.image_filename;
                this.setLocationImage(image);
                setDescriptionHeader(description);
            }
        }
    },
};
</script>

<style scoped lang="scss">
 
::v-deep .owl-nav {
    .owl-next, .owl-prev {
        background-color: $hf-primary;
        color: white;
        &:hover {
            background-color: $hf-primary;
            box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.7);
        }
    }
}

</style>