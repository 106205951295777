<template>
    <div>
        <div class="container">
            <loader item="reserva" estilo="margin-top:200px !important" v-if="loadingReserva" />

            <DynamicLoader
                estilo="margin-top:200px !important"
                :mensaje="mensaje"
                v-if="mensaje.status"
            />

            <Errors :data="errors" :language="$i18n.locale" v-if="errors.status" />

            <div
                v-if="reservaStatus"
                class="row meios-pago shadow justify-content-center"
                style="margin-top:50px !important"
            >
                <div class="row col-12 mt-5">
                    
                    <div class="col-12 titulo">{{$t('payment.title')}}</div>
                    <div class="col-12 text-center font-weight-normal py-3">({{$t('payment.tax')}})</div>

                    <!-- Name -->
                    <div class="col-6 label">{{$t('payment.name')}}</div>
                    <div class="col-6 data">{{userReservation.nombre}}</div>

                    <!-- Check-in -->
                    <div class="col-6 label">CheckIn:</div>
                    <div class="col-6 data">{{ reservation.fecha_inicio }}</div>

                    <!-- Check-out -->
                    <div class="col-6 label">CheckOut:</div>
                    <div class="col-6 data">{{ reservation.fecha_termino }}</div>
                    
                    <!-- Stay -->
                    <div v-if="rentalWarranty.amount == 0" class="col-6 label">{{$t('payment.stay')}}</div>
                    <div v-if="rentalWarranty.amount == 0" class="col-6 data">{{ rentalPrice  | convertAndFormatPrice }}</div>

                    <!-- Warranty -->
                    <div v-if="rentalWarranty.amount > 0" class="col-6 label">{{$t('payment.warranty')}}</div>
                    <div v-if="rentalWarranty.amount > 0" class="col-6 data">{{ rentalWarranty  | convertAndFormatPrice }}</div>

                    <!-- Service -->
                    <div class="col-6 label">{{$t('payment.service')}}</div>
                    <div class="col-6 data">{{ rentalCommission | convertAndFormatPrice }}</div>
                    
                    <!-- Total -->
                    <div class="col-6 label my-5">{{$t('payment.total')}}</div>
                    <div class="col-6 data my-5 font-weight-bold"> {{ totalPrice | convertAndFormatPrice }}</div>
                    
                </div>

                <div class="col-12">
                    
                    <div class="row justify-content-center p-3">
                        
                        <!-- Mercadopago -->
                        <div class="col-12 col-md-2 col-lg-2 p-3 text-center">
                            <a id="MP" class="btn btn-pago" :href="mercadoPago.mercadopago_url">
                                <img src="@/assets/pagos/mercado-pago.png" alt />
                            </a>
                            <label class="payment-gateway-name" for="MP">Mercado Pago {{$t('payment.only')}} $CLP</label>
                            <div class="payment-gateway-charge">(+ {{ configurations.gatewaysCommissions.mercadopago }} %)</div>
                        </div>

                        <!-- Paypal -->
                        <div class="col-12 col-md-2 col-lg-2 p-3 text-center">
                            <a id="PP" :href="payPal.paypal_url" class="btn btn-pago">
                                <img src="@/assets/pagos/paypal.png" alt />
                            </a>
                            <label class="payment-gateway-name" for="PP">PayPal</label>
                            <div class="payment-gateway-charge">(+ {{ configurations.gatewaysCommissions.paypal }} %)</div>
                        </div>
                        
                        <!-- Transfer -->
                        <div class="col-12 col-md-2 col-lg-2 p-3 mb-5 text-center">
                            <button id="TR" class="btn btn-pago btn-fa" style="margin-bottom: 0px" @click="submitTransfer">
                                <i class="fas fa-university" /> <br>
                            </button>
                            <label class="payment-gateway-name" for="TR">{{$t('payment.transfer')}}</label>
                            <div class="payment-gateway-charge">(+ {{ configurations.gatewaysCommissions.bankTransfer }} %)</div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Loader from "@/components/Loader/Loader";
import DynamicLoader from "@/components/Loader/DynamicLoader";
import Errors from "@/components/Payment/Errors";
import { mapActions, mapState } from "vuex";
import configurations from '@/new-constants/configurations';

export default {
    components: {
        Loader,
        Errors,
        DynamicLoader
    },
    data() {
        return {
            loadingReserva: true,
            loadUrlMP: false,
            loadUrlPP: false,
            loadUrlWP: false,
            loadUrlWPCL: false,
            reservaStatus: false,
            mensaje: {
                es:
                    "Por favor espere mientras completamos el proceso de pago...",
                en: "Please wait while we complete the payment process...",
                status: false
            },
            errors: {
                status: false,
                iconClass: "svg-danger danger",
                class: "danger",
                icon: "exclamation-triangle",
                text: {
                    titulo: {
                        es: "Atencion",
                        en: "Atention"
                    },
                    message: {
                        es: "Mesaje de Prueba",
                        en: "Test Message"
                    }
                }
            }
        };
    },
    methods: {
        ...mapActions([
            "getUrlMP",
            "getUrlPP",
            "getReservation",
            "paymentByTransfer",
        ]),
        loadUrlPagos() {

            let data = {
                idreserva: this.$route.params.id,
                token: this.$route.params.token
            };

            if (process.env.NODE_ENV === 'production') {

                this.getUrlMP(data).then(response => {
                    this.loadUrlMP = true;
                    if (this.loadUrlPP) {
                        this.loadingReserva = false;
                        this.reservaStatus = true;
                    }
                });

                this.getUrlPP(data).then(response => {
                    this.loadUrlPP = true;
                    if (this.loadUrlMP) {
                        this.loadingReserva = false;
                        this.reservaStatus = true;
                    }
                });
                
            } else {
                this.loadingReserva = false;
                this.reservaStatus = true;
            }
        
        },
        submitTransfer() {
            let data = {
                id: this.$route.params.id,
                token: this.$route.params.token
            };

            this.mensaje.status = true;
            this.reservaStatus = false;
            let payment = this.paymentByTransfer(data).then(value => {
                if (value === undefined) {
                    this.mensaje.status = false;

                    this.errors = {
                        status: true,
                        iconClass: "svg-danger suceso",
                        class: "suceso",
                        icon: "fas fa-check-circle",
                        text: {
                            titulo: {
                                es: "Felicitaciones",
                                en: "Congratulations"
                            },
                            message: {
                                es:
                                    "Se ha enviado un correo electrónico con la información para la transferencia. Ahora dispones de una hora para poder realizar el depósito y notificarnos del pago para que la reserva quede confirmada. De lo contrario, esta será eliminada automáticamente del sistema.",
                                en:
                                    "An email has been sent with the information for the transfer. Now you have one hour to make the deposit and notify us of the payment so that the reservation is confirmed. Otherwise, it will be automatically removed from the system."
                            }
                        }
                    };
                }
            });
        },
        loadReserva() {
            let data = {
                idreserva: this.$route.params.id,
                token: this.$route.params.token
            };

            this.getReservation(data)
                .then(() => {
                    this.loadUrlPagos();
                })
                .catch(error => {
                    this.loadingReserva = false;
                    this.errors.status = true;
                    switch (error.response.data.code) {
                        case "E01":
                            this.errors.text.message.es =
                                "No se encontró la reserva solicitada.";
                            this.errors.text.message.en =
                                "The requested reservation was not found.";
                            break;
                        case "E02":
                            this.errors.text.message.es =
                                "Esta reserva tardó más de 24 horas en pagarse y fue cancelada por el sistema.";
                            this.errors.text.message.en =
                                "This reservation took over 24 hours to pay and was canceled by the system.";
                            break;
                        case "E03":
                            this.errors.text.message.es =
                                "Lo sentimos, esta reserva ha sido cancelada por el propietario del alojamiento.";
                            this.errors.text.message.en =
                                "Sorry, this reservation has been canceled by the owner of the accommodation.";
                            break;
                        case "E04":
                            this.errors.text.message.es =
                                "Lo sentimos, esta reserva no figura como aceptada en nuestro sistema. Revise el estado de su reserva en el dashboard.";
                            this.errors.text.message.en =
                                "Sorry, this reservation is not listed as accepted in our system. Review the status of your reservation in the dashboard.";
                            break;
                        case "E05":
                            this.errors.text.message.es =
                                "El token para esta solicitud no es válido. Acceda a esta página solo por la dirección enviada a su correo electrónico.";
                            this.errors.text.message.en =
                                "The token for this request is not valid. Please access this page only by the address sent to your email.";
                            break;
                        case "E06":
                            this.errors.text.message.es =
                                "Esta reserva ya está pagada en nuestro sistema.";
                            this.errors.text.message.en =
                                "This reservation is already paid in our system.";
                            break;
                        default:
                            this.errors.text.message.es = "";
                            this.errors.text.message.en = "";
                    }
                });
        }
    },
    computed: {
        
        ...mapState([
            "reservation",
            "webPay",
            "webPayCL",
            "payPal",
            "mercadoPago",
            "hyfPago",
            "userReservation",
            "user"
        ]),

        configurations() {
            return configurations;
        },
        
        rentalPrice() {
            return { 'amount': Number(this.reservation.costo), 'currency': this.reservation.moneda }
        },
        
        totalPrice() {
            
            let total = Number(this.rentalCommission.amount);
            
            if (Number(this.rentalWarranty.amount) > 0) {
                total = total + Number(this.rentalWarranty.amount);     
            }  else {
                total = total + Number(this.rentalPrice.amount);
            }
            return { 'amount': total, 'currency': this.reservation.moneda }
		},
        
        rentalCommission() {
            return { 'amount': Number(this.reservation.comision), 'currency': this.reservation.moneda }
        },

        rentalWarranty() {
            return { 'amount': Number(this.reservation.garantia), 'currency': this.reservation.moneda }
        },

    },
    created() {
        this.loadReserva();
    }
};
</script>

<style scoped lang="scss">

.payment-gateway-name {
    font-size: 1.1rem;
    font-weight: normal;
    margin: 0;
    padding-bottom: 5px !important;
}
.payment-gateway-charge {
    font-size: 0.9rem;
}

.meios-pago {
    background: #ffffff;
    font: Bold 14px/14px Lato;
    color: #666;
}

.meios-pago label {
    padding: 10px 5px;
    color: $hf-sec-pine;
    line-height: 20px;
}

.btn-pago {
    background-image: linear-gradient(to top, #f3f3f3, #fff);
    border: #f5f5f5 2px solid;
    color: $hf-sec-pine;
    width: 100%;
    height: 100px;
    margin-top: 0;

    &:hover {
        background-image: linear-gradient(to top, #f3f3f3, #fff);
        border: #f5f5f5 2px solid;
        color: $hf-sec-pine;
        width: 100%;
        height: 100px;
        margin-top: 0;
    }

    img {
        max-width: 100%;        
    }
}



a img {
    margin-top: 25px;
}

.btn-pago svg {
    max-width: 100%;
}

.btn-fa svg {
    font-size: 25px;
}

.titulo {
    font-size: 20px;
    text-align: center;
}

.label {
    text-align: right;
    margin-top: 20px;
}

.data {
    margin-top: 20px;
    font-weight: normal;
    text-align: left;
}
</style>