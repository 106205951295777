<template>
    <b-container>

        <!-- Title -->
        <h1>{{$t('bank.banking-information')}}</h1>
        
        <b-row>

            <!-- Init text -->
            <b-col sm="12" class="init-text">{{$t('bank.init-text')}}</b-col>

            <b-col md="6" sm="12">
                <div class="card">

                    <!-- Owner info -->
                    <h2>{{ $t('bank.owner-information') }}</h2>

                    <!-- Firstname -->
                    <ValidatedInput
                        fieldName="first_name"
                        v-model="formData.first_name"
                        :label="$t('bank.owner-firstname')"
                        :placeholder="$t('bank.owner-firstname-placeholder')"
                        :validationObject="$v.formData.first_name"
                    />

                    <!-- Lastname -->
                    <ValidatedInput
                        fieldName="last_name"
                        v-model="formData.last_name"
                        :label="$t('bank.owner-lastname')"
                        :placeholder="$t('bank.owner-lastname-placeholder')"
                        :validationObject="$v.formData.last_name"
                    />

                    <!-- Government ID -->
                    <ValidatedInput
                        fieldName="government_id"
                        v-model="formData.government_id"
                        :label="$t('bank.owner-id')"
                        :placeholder="$t('bank.owner-id-placeholder')"
                        :validationObject="$v.formData.government_id"
                    />

                    <!-- Phone -->
                    <ValidatedPhone
                        fieldName="phone"
                        v-model="formData.phone"
                        :label="$t('bank.owner-phone')"
                        :placeholder="$t('bank.owner-phone-placeholder')"
                        :validationObject="$v.formData.phone"
                    />

                    <!-- Email -->
                    <ValidatedInput
                        fieldName="email"
                        v-model="formData.email"
                        :label="$t('bank.owner-email')"
                        :placeholder="$t('bank.owner-email-placeholder')"
                        :validationObject="$v.formData.email"
                    />
                </div>

                <div class="card">

                    <!-- Account info -->
                    <h2>{{ $t('bank.account-information') }}</h2>

                    <!-- Bank account number -->
                    <ValidatedInput
                        fieldName="bank_account_number"
                        v-model="formData.bank_account_number"
                        :label="$t('bank.account-number')"
                        :placeholder="$t('bank.account-number-placeholder')"
                        :validationObject="$v.formData.bank_account_number"
                    />

                    <!-- Bank code -->
                    <ValidatedInput
                        fieldName="bank_code"
                        v-model="formData.bank_code"
                        :label="$t('bank.bank-code')"
                        :placeholder="$t('bank.bank-code-placeholder')"
                        :validationObject="$v.formData.bank_code"
                    />
                </div>
            </b-col>

            <b-col md="6" sm="12">
                <div class="card">

                    <!-- Bank info -->
                    <h2>{{ $t('bank.bank-information') }}</h2>

                    <!-- Bank name -->
                    <ValidatedInput
                        fieldName="bank_name"
                        v-model="formData.bank_name"
                        :label="$t('bank.bank-name')"
                        :placeholder="$t('bank.bank-name-placeholder')"
                        :validationObject="$v.formData.bank_name"
                    />

                    <!-- Bank address -->
                    <ValidatedInput
                        fieldName="bank_address"
                        v-model="formData.bank_address"
                        :label="$t('bank.bank-address')"
                        :placeholder="$t('bank.bank-address-placeholder')"
                        :validationObject="$v.formData.bank_address"
                    />

                    <!-- Branch code -->
                    <ValidatedInput
                        fieldName="branch_code"
                        v-model="formData.branch_code"
                        :label="$t('bank.branch-code')"
                        :placeholder="$t('bank.branch-code-placeholder')"
                        :validationObject="$v.formData.branch_code"
                    />

                    <!-- Message -->
                    <ValidatedTextArea
                        fieldName="message"
                        v-model="formData.message"
                        :label="$t('bank.message')"
                        :placeholder="$t('bank.message-placeholder')"
                        :validationObject="$v.formData.message"
                    />
                    
                </div>
            </b-col>

            <b-col sm="12">
                <Button @click="handleSubmit" class="new-btn w-100 mt-3" variant="primary">{{ $t('bank.save') }}</Button>
            </b-col>

        </b-row>

        <!-- Alert modal -->
        <AlertModal 
            :message="modalMessage" 
            :visible="showAlert" 
            @hideAlert="hideAlert" 
        />

    </b-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Button from "@/components/Ui/Button.vue";
import { validations } from "@/components/ValidatedFields/bankInformationRules";
import ValidatedInput from "@/components/ValidatedFields/ValidatedInput.vue";
import ValidatedTextArea from "@/components/ValidatedFields/ValidatedTextArea.vue";
import ValidatedPhone from "@/components/ValidatedFields/ValidatedPhone.vue";
import AlertModal from "@/components/AlertModal.vue";

export default {
    name: "BankInformation",
    components: {
        Button,
        ValidatedInput,
        ValidatedTextArea,
        ValidatedPhone,
        AlertModal
    },
    data() {
        return {
            formData: {},
            showModal: false,
            modalMessage: '',
            showAlert: false,
        };
    },
    validations: validations,
    methods: {
        ...mapActions("bankData", [
            "getBankData",
            "putBankData"
        ]),

        hideAlert() {
            this.showAlert = false;
        },

        async handleSubmit() {
            
            // Validate form
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.modalMessage = this.$t('validations.alert');
                this.showAlert = true;
                window.scrollTo(0, 0);
                
                // // Print errors in console
                // console.log('Validation errors:', this.$v.formData);
                // Object.keys(this.$v.formData).forEach(field => {
                //     console.log(`Validation status for ${field}:`, this.$v.formData[field])
                // })
                
                return;
            }

            // If the validation is successful, send the data
            try {
                await this.putBankData(this.formData);
                this.modalMessage = this.$t('bank.successfully-updated');
                this.showAlert = true;
            } catch (error) {
                this.modalMessage = error.message;
                this.showAlert = true;
            }
        }
    },
    computed: {
        ...mapState("bankData", [
            "bankData",
        ]),
    },
    created() {
        this.getBankData();
    },
    watch: {
        bankData(newVal) {
            if (newVal) {
                this.formData = { ...newVal };
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@/new-assets/styles";

.init-text {
    font-size: 20px;
    margin-bottom: 28px;
}

h2 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.card {
    border: none;

    @media (min-width: $md) {
        padding-right: 30px;
    }
}

.btn {
    border-radius: $border-radius-new;
}

</style>
