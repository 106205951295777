<template>
    <div class="language-switcher">

        <div class="dropdown">
            <button class="dropdown-toggle" data-toggle="dropdown">
                <span class="icon"><i class="fas fa-globe"></i></span>
                <span class="lang-text">{{ currentLangText }}</span>
                <i class="fas fa-chevron-down"></i>
            </button>

            <div class="dropdown-menu">
                <a
                    v-for="(lang, index) in langs"
                    :key="index"
                    class="dropdown-item"
                    href="#"
                    @click.prevent="changeLang(lang.code)"
                >
                    {{ lang.text }}
                </a>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    name: "LocaleChanger",
    props: {
        bgwhite: Boolean,
    },
    data() {
        return {
            langs: [
                { code: "es", text: "ESP" },
                { code: "en", text: "ENG" },
            ],
            selectedLang: "",
        };
    },
    computed: {
        currentLangText() {
            const lang = this.langs.find((l) => l.code === this.selectedLang);
            return lang ? lang.text : "";
        },
    },
    methods: {
        changeLang(langCode) {
            this.selectedLang = langCode;
            this.$i18n.locale = langCode;
            this.$store.state.iso639_1 = langCode;
            localStorage.setItem("iso639_1", langCode);
            if (this.$store.getters.isLoggedIn) {
                this.$store.dispatch("changePreferLanguage", { language: langCode });
            }
        },
    },
    created() {
        const storedLang = localStorage.getItem("iso639_1");
        this.selectedLang = storedLang ? storedLang : this.$i18n.locale;
    },
    watch: {
        selectedLang: function (newVal) {
            this.changeLang(newVal);
        },
    },
};
</script>

<style lang="scss" scoped>

.language-switcher {
    
    .dropdown-toggle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #fef7f0;
        color: #f48225;
        border: 1px solid #f48225;
        border-radius: 30px;
        padding: 14px 15px;
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;
        width: auto;

        &:after {
            display: none;
        }

        &:hover {
            background-color: #fbe9dd;
        }

        .icon {
            font-weight: bold;
            margin-right: 5px;
            font-size: 16px;
        }

        .lang-text {
            margin-right: 5px;
        }

        i {
            font-size: 12px;
        }

        @media (min-width: $md) {
            padding: 5px 15px;
            border-radius: 20px;
        }
    }

    .dropdown-menu {
        border: 1px solid #f48225;
        border-radius: 20px;
        background-color: #fff;
        min-width: 100%;
        max-width: 100%;
        text-align: center;
        overflow: hidden;

        @media (max-width: $md) {
            min-width: 100px !important;
        }

        .dropdown-item {
            color: #f48225;
            padding: 5px;

            &:hover {
                background-color: #fadbca;
            }
        }
    }
    
}
</style>
