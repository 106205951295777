<template>
    <div>
        <div class="banner-container">
            <div class="img">
                <img :src="cityImage" alt="City Banner" />
            </div>

            <!-- Desktop search menu -->
            <div class="search-menu desktop-only">
                <div class="col column-left">
                    <div class="banner-container__search">
                        <SearchBanner />
                    </div>
                </div>

                <!-- Subtitle -->
                <div class="col column-right">
                    <h2 v-if="computedSubTitle" class="subtitle">{{ computedSubTitle }}</h2>
                </div>
            </div>
        </div>

        <!-- Mobile search menu -->
        <div class="mobile-items mobile-only">
            <SearchBanner class="w-100" />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import deviceMixin from "@/mixins/deviceMixin";
import SearchBanner from "@/new-components/Search/SearchBanner.vue";
import { assets } from "@/new-constants/assets";
import { loadCityImage } from "@/Utils/seo";

export default {
    name: "Banner",
    components: {
        SearchBanner,
    },
    mixins: [deviceMixin],
    data() {
        return {
            assets,
            cityImage: assets.bannerBackgroundSrc,
        };
    },
    computed: {
        ...mapState("searchQuery", ["locationDescription", "locationImage"]),
        computedSubTitle() {
            if (this.$route.name === "search-query") {
                return this.locationDescription;
            }
        },
        showLocationDescriptionMobile() {
            return this.locationDescription && this.isMobile;
        },
    },
    watch: {
        locationImage(newVal) {
            this.cityImage = loadCityImage(newVal);
        },
    },
};
</script>

<style lang="scss" scoped>
.banner-container {

    height: 600px;
    @media (max-width: $xl) {
        height: 40vh !important;
    }

    width: 100%;
    position: relative;

    .img {
        width: 100%;
        height: 100% !important;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            display: block;
        }
    }

    &__search {
        display: flex;
        align-items: center;
        height: 480px;
        max-width: 614px;
        padding: 25px 0px 25px 35px;
        background-image: url("~@/new-assets/search-box-background.svg");
        background-repeat: no-repeat;
        background-size: contain;
    }

    .search-menu {
        position: absolute;
        top: 0;
        width: 100%;
    }

    .column-left {
        width: 52%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 4%;
    }

    .column-right {
        width: 48%;
        padding: 50px 80px 50px 0;
        margin-top: 5%;
    }

    .subtitle {
        backdrop-filter: brightness(0.5);
        padding: 50px;
        line-height: 1.8;
        font-weight: 400;
        color: white;
        font-size: 2em;
    }
}

.mobile-items {
    padding: 15px;
    z-index: 1;
}
</style>
