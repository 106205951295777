<template>
    <div class="country-selection">

        <!-- User country -->
        <label for="user-country" class="form-label">{{ $t('register-new.user-country') }}:</label>
        <select
            v-model="formData.user_country"
            name="user-country"
            class="form-control input country-select"
            id="user-country"
            required
        >
            <optgroup>
                <option
                    v-for="(item, i) in interestCountries"
                    :key="`c-${i}`"
                    :data-country-code="item.code"
                >
                    {{ item.name }}
                </option>
            </optgroup>
        </select>
        
        <!-- Accommodation/Destination country -->
        <label for="interest-country" class="form-label">{{ countryLabel }}:</label>
        <select
            v-model="formData.interest_country"
            name="interest-country"
            class="form-control input"
            id="interest-country"
            required
        >
            <optgroup>
                <option
                    v-for="(item, i) in accommodationCountries"
                    :key="`c-${i}`"
                    :data-country-code="item.code"
                >
                    {{ item.name }}
                </option>
            </optgroup>
        </select>

        <!-- Continue button -->
        <div class="continue-button-div">
            <Button @click="handleSubmit" class="new-btn continue" variant="sec-pine">
                {{ $t('register-new.continue') }}
            </Button>
        </div>
        
    </div>
</template>

<script>
import userCountryList from "@/assets/data/users-countries-data.json";
import accommodationCountryList from "@/assets/data/accommodations-countries-data.json";
import { countryValidations } from "@/components/ValidatedFields/registerRules";

import { mapState, mapActions } from "vuex";

import Button from "@/components/Ui/Button.vue";

export default {
    name: "Register",
    components: {
        Button
    },
    validations: countryValidations,
    data() {
        return {
            formData: {
                interest_country: null,
                user_country: null,
            },
            userCountryList: userCountryList,
            accommodationCountryList: accommodationCountryList
        };
    },
    methods: {

        ...mapActions("userNew", [
            "setUserCountry"
        ]),

        async handleSubmit() {
            this.$v.$touch();

            // Don't submit if there are errors
            if (this.$v.$invalid) {
                console.log(this.$v);
                return;
            }
            
            const data = { 
                'user_country': this.formData.user_country,
                'interest_country': this.formData.interest_country
            }
            try {
                const response = await this.setUserCountry(data);
                this.$emit('next-step');
            } catch (e) {
                this.handleError(e);
            }
        }
        
    },
    computed: {
        ...mapState("userNew", [
            "userType"
        ]),
        interestCountries() {
            return this.userCountryList.map(item => {
                return {
                    code: item.code,
                    name: item.name.replace(/\s\(\+\d+\)$/, '')
                };
            });
        },
        accommodationCountries() {
            return this.accommodationCountryList.map(item => {
                return {
                    code: item.code,
                    name: item.name.replace(/\s\(\+\d+\)$/, '')
                };
            });
        },
        countryLabel() {
            if(this.userType === 'huesped') {
                return this.$t("register-new.destination-country");
            } else if (this.userType === 'anfitrion') {
                return this.$t("register-new.accommodation-country");
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@/new-assets/styles";

.country-selection {
    margin: 60px auto 0 auto;
    width: 100%;
    max-width: 400px;
}

.country-select {
    margin-bottom: 50px;
}

.continue-button-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
}

</style>
