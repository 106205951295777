import axios from 'axios';
import * as types from './types';
import apiUrls from "@/new-constants/apiUrls";
import { formatDate } from "@/Utils/Utils";

export default {

    setLocationDescription ({ commit, state, dispatch }, locationDescription) {
        commit(types.SET_LOCATION_DESCRIPTION, locationDescription);
    },
    setLocationImage ({ commit, state, dispatch }, locationImage) {
        commit(types.SET_LOCATION_IMAGE, locationImage);
    },
    setSearchQueryLocation ({ commit, state, dispatch }, searchQueryLocation) {
        commit(types.SET_SEARCH_QUERY_LOCATION, searchQueryLocation);
    },
    
};
