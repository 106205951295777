<template>
    <div class="validated-text-input-main-div">

        <!-- Field Label -->
        <label :for="fieldName" class="label">{{ label }}</label>

        <!-- Textarea -->
        <textarea
            :id="fieldName"
            v-model="localModel"
            class="text-area form-control"
            :placeholder="placeholder"
            rows="4"
            :class="{ 'is-invalid': hasErrors }"
        ></textarea>

        <!-- Error message -->
<!--        <div v-if="hasErrors" class="invalid-feedback">-->
<!--            <div v-for="rule in errorRules" :key="rule">-->
<!--                {{ getErrorMessage(rule) }}-->
<!--            </div>-->
<!--        </div>-->

        <div class="invalid-feedback">
            <div v-if="apiError">{{ apiError }}</div>
            <div v-else-if="hasErrors" v-for="rule in errorRules" :key="rule" class="error-text">
                {{ getErrorMessage(rule) }}
            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: {
        fieldName: {
            type: String,
            required: true
        },
        value: {
            required: true
        },
        label: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            required: true
        },
        validationObject: {
            type: Object,
            required: true
        }
    },
    computed: {
        localModel: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        currentValidation() {
            return this.validationObject;
        },
        errorRules() {
            if (!this.currentValidation || !this.currentValidation.$params) return [];
            return Object.keys(this.currentValidation.$params).filter(rule => this.currentValidation.$error && !this.currentValidation[rule]);
        },
        hasErrors() {
            return this.errorRules.length > 0;
        }
    },
    methods: {
        getErrorMessage(rule) {
            const messagePath = `validations.${rule}`;
            const params = this.currentValidation.$params[rule] || {};
            return this.$t(messagePath, params);
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@/new-assets/styles";
</style>

