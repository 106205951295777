import axios from 'axios';
import * as types from './types';
import apiUrls from '@/new-constants/apiUrls';

export default {

    async postIcalUrl({ dispatch }, { icalUrl, accommodationId }) {
    
        const url = `${ apiUrls.icalUrl }`;

        try {
            const response = await axios.post(url, {
                icalUrl,
                accommodationId,
            });

            if (response.status !== 200) { return false; }

            dispatch('setIcalUrl', icalUrl);
            
            return true;

        } catch (error) {
            return 'Hubo un error al comunicarse con el servidor: ' . error;
        }
    
    },
    
    async fetchIcalUrl({ commit }, accommodationId) {

        const url = `${apiUrls.icalUrl}/${accommodationId}`;

        try {
            const response = await axios.get(url);
            
            if (response.status !== 200) {
                console.error('Hubo un error al obtener la URL del iCal');
            } else {
                commit(types.SET_ICAL_URL, response.data.url);
            }

        } catch (error) {
            console.error('Hubo un error al comunicarse con el servidor', error);
        }
    },
    
    setIcalUrl({ commit }, icalUrl) {
        commit(types.SET_ICAL_URL, icalUrl);
    },
    
    async syncWithExternalIcal({ dispatch }, accommodationId) {
            
        const url = `${apiUrls.syncWithExternalIcal}/${accommodationId}`;
        
        try {
            const response = await axios.get(url);

            if (response.status !== 200) { return false; }
            
            return true;
            
        } catch (error) {
            console.error('Error al sincronizar. ', error);
        }
    }

};
