import axios from 'axios';
import * as types from './types';
import apiUrls from '@/new-constants/apiUrls';

export default {

    async fetchAccommodationById({ commit, state, dispatch }, accommodationId) {

        const url = `${apiUrls.getAccommodation}${accommodationId}`;

        // Fetch the accommodation by its ID
        const response = await axios.get(url);
        const accommodation = response.data.accomodation; // FIXME: the API responds with a key called "accomodation" instead of "accommodation"
        dispatch('setAccommodation', accommodation);
    },

    setAccommodation({ commit, state, dispatch }, accommodation) {
        commit(types.SET_ACCOMMODATION, accommodation);
    },

};
