<template>
    <b-pagination 
        v-model="items.current_page" 
        :total-rows="items.total" 
        :per-page="perPage" 
        align="center"
        @change="pageChanged" 
        class="mt-5" />
</template> {}

<script>
import configurations from '@/new-constants/configurations';

export default {
    name: "NewPaginator",
    props: [
        "items",
        "fetchMethod",
        "additionalParams"
    ],
    data() {
        return {
            page: 1,
            perPage: configurations.paginator.perPage
        }
    },
    methods: {
        
        pageChanged(page) {
            
            const params = {
                page: page,
                perPage: this.perPage,
                ...this.additionalParams
            };
            
            this.fetchMethod(params);
            
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            this.updateURL(params);
            this.$emit('page-changed', page);
        },

        updateURL(params) {
            this.$router.replace({ query: params });
        },

        refetch() {
            const params = {
                page: this.items.current_page,
                perPage: this.perPage,
                ...this.additionalParams
            };
            this.fetchMethod(params);
        }
    },

    
    watch: {
        '$route.path': {
            immediate: true,
            async handler() {
                this.pageChanged(1)
            },
        },
    }
};
</script>

<style lang="scss" scoped>

::v-deep .page-item.active .page-link {
    color: #fff !important;
    background-color: $hf-sec-pine !important;
    border: 1px solid $hf-sec-pine !important;
}

::v-deep .page-link {
    color: $hf-sec-pine !important;
}

</style>