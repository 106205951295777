<template>
    <div class="container">
        <h1>{{$t('dashboard-side-menu.my-favorites')}}</h1>
        <div v-if="loading">
            <Loader />
        </div>

        <div v-if="alojamientos">
            <div class="row" v-show="!loading">
                <div
                    v-for="alojamiento in alojamientos"
                    :key="alojamiento.idalojamiento"
                    class="col-md-4 col-sm-12 mt-3"
                    @show="getPhotos(alojamiento.idalojamiento)"
                >
                    <router-link class="card" :to="accommodationLink(alojamiento.idalojamiento)">
                        <CarouselList :idAlojamiento="alojamiento.idalojamiento" :externalReference="alojamiento.external_reference" />
                        <div class="favoritos-container__actions">
                            <img
                                @click.prevent="removeWishList(alojamiento.idalojamiento)"
                                class="action-item"
                                src="@/assets/corazon.svg"
                            />
                            <img
                                @click.prevent="$bvModal.show('modal-' + alojamiento.idalojamiento)"
                                class="action-item"
                                src="@/assets/compartir.svg"
                            />
                        </div>

                        <div class="card-body">
                            <router-link
                                class="card-title"
                                :to="'alojamiento/'+alojamiento.idalojamiento"
                            >
                                <h4>{{JSON.parse(alojamiento.nombre)[$i18n.locale]}}</h4>
                            </router-link>
                            <div
                                v-if="alojamiento.aprobado === 1"
                                v-b-popover.hover="$t('my-accommodations.approved')"
                                class="approved"
                            >
                                <i class="fas fa-circle" />
                            </div>
                            <div
                                v-else-if="alojamiento.aprobado === 2"
                                v-b-popover.hover="$t('my-accommodations.pending')"
                                class="pending"
                            >
                                <i class="fas fa-circle" />
                            </div>
                            <p class="address">{{alojamiento.direccion}}</p>
                        </div>
                    </router-link>
                    
                    <b-modal
                        :id="'modal-' + alojamiento.idalojamiento"
                        hide-footer
                        title="Compartir alojamiento"
                    >
                        <p>{{$t('my-accommodations.share-message')}}:</p>

                        <p id="textoACopiar">
                            {{url+'/alojamiento/'+alojamiento.idalojamiento}}
                            <button
                                @click.prevent="obtieneUrl()"
                            >
                                <i class="fas fa-copy" />
                            </button>
                        </p>

                        <p>{{$t('my-accommodations.share-socials')}}:</p>
                        <div class="socials">
                            <a
                                target="_blank"
                                :href="'whatsapp://send?text='+url+'/alojamiento/'+alojamiento.idalojamiento"
                            >
                                <i class="fab fa-whatsapp" />
                            </a>
                            <a
                                target="_blank"
                                :href="'http://www.facebook.com/sharer/sharer.php?u='+url+'/alojamiento/'+alojamiento.idalojamiento"
                            >
                                <i class="fab fa-facebook-f" />
                            </a>
                            <a
                                target="_blank"
                                :href="'https://twitter.com/share?url='+url+'/alojamiento/'+alojamiento.idalojamiento+'&via=houseandflatschile'"
                            >
                                <i class="fab fa-twitter" />
                            </a>
                        </div>
                    </b-modal>
                </div>
            </div>
        </div>

        <div v-else>
            <h4>{{$t('favorites.not-found')}}</h4>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import router from "@/router";
import Loader from "@/components/Loader/Loader";
import CarouselList from "@/components/Carousel/CarouselList";
import axios from "axios";

export default {
    name: "Favoritos",
    components: {
        Loader,
        CarouselList
    },
    data() {
        return {
            loading: true,
            url: process.env.VUE_APP_API_URL,
            alojamientos: {}
        };
    },
    methods: {
        ...mapActions(["wishList"]),

        accommodationLink(accommodationId) {
            if(this.$route.query.fecha_inicio && this.$route.query.fecha_termino)
                return "/alojamiento/" + accommodationId + '/' + this.$route.query.fecha_inicio + '/' + this.$route.query.fecha_termino;
            else
                return "/alojamiento/" + accommodationId;
        },

        removeWishList(id) {
            this.loading = true;

            let dataFav = {
                alojamiento_idalojamiento: id
            };

            axios.post(this.url + "wishList/change", dataFav).then(() => {
                this.loadFavs();
            });
        },

        loadFavs() {
            axios.get(this.url + "favorites").then(value => {
                this.alojamientos = value.data;
                this.loading = false;
            });
        }
    },
    created() {
        this.loadFavs();
        window.scrollTo(0, 0);
    }
};
</script>

<style lang="scss" scoped>
.container {
    h1 {
        color: $hf-sec-pine;
        font-size: 1.8em;
        //border-bottom: solid rgb(231, 231, 231) 0.5px;
        padding-bottom: 10px;
        margin-bottom: 20px;
        text-align: right;
    }
    
    h4 {
        padding-top: 0;
        margin-top: 0;
    }

    .favoritos-container__actions {
        padding: 10px;
    }

    &__actions {
        display: flex;
        padding: 5px 0;
    }

    .action-item {
        cursor: pointer;
        padding: 0 4px;
    }

    .card-title {
        color: $hf-sec-pine;
        font-size: 18px;
        &:hover {
            text-decoration: none;
        }
        h4 {
            height: 86px;
        }
    }
    
    .card-body {
        .address {
            height: 48px;;
        }
    }

    .card-footer {
        background-color: $hf-sec-pine;
        color: white;
    }

    .approved {
        color: green;
        display: inline-block;
    }

    .pending {
        color: red;
        display: inline-block;
    }
}

.socials {
    font-size: 2em;
    display: flex;
    justify-content: space-around;
    a {
        color: $hf-sec-pine;
    }
}
</style>