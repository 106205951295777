import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import Axios from 'axios'

import VueFormWizard from 'vue-form-wizard'
import Vuelidate from 'vuelidate'
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm'
import VueTabs from 'vue-nav-tabs/dist/vue-tabs'
import * as VueGoogleMaps from 'vue2-google-maps'
import VCalendar from 'v-calendar';
import sanitizeHTML from 'sanitize-html';
import vueSmoothScroll from 'vue2-smooth-scroll'
import Toasted from 'vue-toasted'

import VueI18n from 'vue-i18n';
import messages from './lang';

import 'dotenv/config';

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-nav-tabs/themes/vue-tabs.css'
import '@/styles.css';
import '@/new-assets/responsive.css';

import { mask } from "vue-the-mask";
import filters from './Utils/filters';
import { checkAppVersion } from './appVersionCheck'

import injectInitialState from './Utils/inject_initial_state';
import DefaultLayout from "@/layouts/DefaultLayout.vue";

Vue.component('default-layout', DefaultLayout);

Vue.use(BootstrapVue);
Vue.use(VueFormWizard);
Vue.use(Vuelidate);
Vue.use(VueTabs);
Vue.use(vueSmoothScroll);
Vue.use(Toasted);

Vue.directive('mask', mask);

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCevwnAx6XUOYAwNnp8BXcvg8FPj2nXqMQ',
        libraries: 'places',
        v: '3.55.11a',
    },
    installComponents: true
})

Vue.use(VCalendar, {
    firstDayOfWeek: 2,  // Monday
});

Vue.prototype.$http = Axios;
Vue.prototype.$sanitize = sanitizeHTML;

// Add the token
const token = localStorage.getItem('token')
if (token) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

// Check the app version
Vue.prototype.$http.interceptors.response.use(function(res) {
    checkAppVersion(res);
    return res;
}, function(error) {
    return Promise.reject(error);
});

Vue.config.productionTip = false

Vue.use(VueI18n);
export const i18n = new VueI18n({
    locale: navigator.language.split('-')[0], // Set the browser language as default
    fallbackLocale: 'es',
    messages
});


const GoogleSignInButton = Vue.directive('google-signin-button', {
    bind: function (el, binding, vnode) {
        CheckComponentMethods()
        let clientId = binding.value
        let googleSignInAPI = document.createElement('script')
        googleSignInAPI.setAttribute('src', 'https://apis.google.com/js/api:client.js')
        document.head.appendChild(googleSignInAPI)

        googleSignInAPI.onload = InitGoogleButton

        function InitGoogleButton() {
            gapi.load('auth2', () => {
                const auth2 = gapi.auth2.init({
                    client_id: clientId,
                    cookiepolicy: 'single_host_origin'
                })
                auth2.attachClickHandler(el, {},
                    OnSuccess,
                    Onfail
                )
            })
        }
        function OnSuccess(googleUser) {
            vnode.context.OnGoogleAuthSuccess(googleUser.getBasicProfile())
            googleUser.disconnect()
        }
        function Onfail(error) {
            vnode.context.OnGoogleAuthFail(error)
        }
        function CheckComponentMethods() {
            if (!vnode.context.OnGoogleAuthSuccess) {
                throw new Error('The method OnGoogleAuthSuccess must be defined on the component')
            }

            if (!vnode.context.OnGoogleAuthFail) {
                throw new Error('The method OnGoogleAuthFail must be defined on the component')
            }
        }
    }
})

Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key]);
});

const app = new Vue({
    GoogleSignInButton,
    router,
    store,
    i18n,
    created() {
    },
    render: h => h(App)
})

if (window.__INITIAL_STATE__) store.replaceState(window.__INITIAL_STATE__)

router.beforeResolve(async (to, from, next) => {
    try {
        const components = router.getMatchedComponents(to)
        await Promise.all(components.map(x => x.fetch && x.fetch({ store })))

        if (window.__PRERENDER_INJECTED) injectInitialState(store.state)
    } catch (error) {
        console.log(error)
    }
    return next()
})

app.$mount('#app');
