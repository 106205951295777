<template>
    <div class="googlebutton-main-div">
        
        <!-- Divider -->
        <div class="divider">
            <div class="line"></div>
            <div class="space"></div>
            <div class="line"></div>
        </div>

        <!-- Google button -->
        <div class="google-button-div">
            <Button class="new-btn btn--thick" v-google-signin-button="clientId" variant="white">
                <i class="fab fa-google icon"></i>
                {{$t('login.sign-in-google')}}
            </Button>
        </div>

    </div>
</template>

<script>
import { mapActions } from "vuex";

import Button from "@/components/Ui/Button.vue";
import { askMissingUserData } from "@/Utils/Utils";
import { redirectToSavedUrl } from "@/Utils/Utils";

export default {
    name: "GoogleButton",
    components: {
        Button
    },
    data() {
        return {
            clientId: process.env.VUE_APP_GOOGLE_LOGIN,
        };
    },
    methods: {
        ...mapActions([
            "createSession",
            "googleLogin"
        ]),

        OnGoogleAuthSuccess(profile) {
            const data = {};
            data.correo = profile.getEmail();
            data.idGP = profile.getId();
            data.nombre = profile.getName();
            data.imagen = profile.getImageUrl();
            this.googleLogin(data).then(response => {
                this.createSession(response);
                if(!askMissingUserData(response, this.$router)) {
                    redirectToSavedUrl(this.$router);
                }
            });
        },
        OnGoogleAuthFail(error) {
            console.log(error);
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@/new-assets/styles";

.divider {
    padding: 50px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .line {
        border-top: 1px solid $hf-sec-carbon;
        flex: 1;
    }

    .space {
        width: 30px;
    }
}

.google-button-div {
    display: flex;
    justify-content: center;
    padding-top: 50px;

    .new-btn {
        border: 1px solid $grey-50;
    }

    .icon {
        margin-right: 10px;
    }
}

</style>