<template>
    <div class="validated-input-main-div">

        <!-- Field -->
        <label :for="fieldName" class="form-label">{{ label }}</label>
        <input
            :type="type"
            :id="fieldName"
            v-model="localModel"
            :class="['form-control', input, { 'is-invalid': hasErrors }]"
            :placeholder="placeholder"
            @input="clearError"
        />
        
        <!-- Error message -->
        <div class="invalid-feedback">
            <div v-if="apiError">{{ apiError }}</div>
            <div v-else-if="hasErrors" v-for="rule in errorRules" :key="rule" class="error-text">
                {{ getErrorMessage(rule) }}
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    props: {
        fieldName: {
            type: String,
            required: true
        },
        value: {
            required: true
        },
        label: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            required: true
        },
        validationObject: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            default: 'text'
        },
        input: {
            type: String,
            default: 'input'
        },
        apiError: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
        };
    },
    computed: {
        localModel: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        currentValidation() {
            return this.validationObject;
        },
        errorRules() {
            if (!this.currentValidation || !this.currentValidation.$params) return [];
            return Object.keys(this.currentValidation.$params).filter(rule => this.currentValidation.$error && !this.currentValidation[rule]);
        },
        hasErrors() {
            return this.errorRules.length > 0 || this.apiError;
        }
    },
    methods: {
        getErrorMessage(rule) {
            const messagePath = `validations.${rule}`;
            const params = this.currentValidation.$params[rule] || {};
            return this.$t(messagePath, params);
        },
        clearError() {
            this.$emit('clear-error', this.fieldName);
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@/new-assets/styles";

.validated-input-main-div {
    margin-bottom: 10px;
}

</style>
