import MadeReservationsList from '@/pages/Dashboard/MadeReservationsList.vue';
import ReceivedReservationsList from '@/pages/Dashboard/ReceivedReservationsList.vue';

export default [
    {
        path: '/reservations-made',
        name: 'reservations-made',
        component: MadeReservationsList,
        meta: {
            requiresAuth: true,
            showDashboard: true,
        }
    },
    {
        path: '/reservations-received',
        name: 'reservations-received',
        component: ReceivedReservationsList,
        meta: {
            requiresAuth: true,
            showDashboard: true,
        }
    }
]