<template>
    <div data-view>
        <Banner />
        <SearchQueryResults />
        <RelatedSearchQuery v-if="relatedQueries" :related-queries="relatedQueries" />
        <Benefits />
    </div>
</template>

<script>
import { mapState } from "vuex";
import Banner from "@/new-components/Banner/Banner.vue";
import SearchQueryResults from "@/components/SearchQuery/SearchQueryResults";
import RelatedSearchQuery from "@/components/SearchQuery/RelatedSearchQuery.vue";
import Benefits from "@/components/Benefits/Benefits";
import {addCanonicalTag, addHreflangTags, getRelatedCitiesLinks} from '@/Utils/seo';

export default {
    name: "SearchQuery",
    components: {
        Banner,
        SearchQueryResults,
        RelatedSearchQuery,
        Benefits,
    },
    data() {
        return {
            relatedQueries: null,
        }
    },
    created() {
        this.addHreflangAndCanonical();
        
    },
    computed: {
        ...mapState('searchQuery', [
            'searchQueryLocation'
        ]),
    },
    methods: {
        addHreflangAndCanonical() {
            const currentUrl = this.$route.fullPath;  // Full current URL
            addHreflangTags(currentUrl);
            addCanonicalTag(currentUrl);
        }
    },
    watch: {
        searchQueryLocation(newVal) {
            this.relatedQueries = getRelatedCitiesLinks(newVal);
        }
    }
};
</script>
