<template>
	<div class="container">
		<form @submit.prevent="saveProfile(perfil)">
			<h1>{{$t('dashboard-side-menu.edit-profile')}}</h1>

            <div class="form-group">
				{{$t('edit-profile.mail')}}
				<input
					v-model="perfil.correo"
                    :disabled="perfil.correo"
					type="text"
					class="form-control"
					placeholder="Jon doe"
                    required
				/>
			</div>

			<div class="form-group">
				{{$t('edit-profile.name')}}
				<input
					v-model="perfil.nombre"
					type="text"
					class="form-control"
					placeholder="Jon doe"
                    required
				/>
			</div>

			<div class="form-group">
				{{$t('edit-profile.lastname')}}
				<input
					v-model="perfil.apellido"
					type="text"
					class="form-control"
					placeholder="Jon doe"
                    required
				/>
			</div>

			<div class="form-group">
				{{$t('edit-profile.phone')}}
				<input
					v-model="perfil.telefono"
					type="text"
					class="form-control"
					placeholder="+569929294954"
                    required
				/>
			</div>

			<div class="form-group">
				<div class="row">
					<div class="col-12 col-md-4 mb-3">
						{{$t('edit-profile.date-birth')}}
						<input
							v-model="perfil.fecha_nacimiento"
							placeholder="dd/mm/yyyy"
							type="date"
							class="form-control"
							required
						/>
					</div>
					<div class="col-12 col-md-4 mb-3">
						{{$t('edit-profile.nationality')}}
						<select v-model="perfil.pais_idpais" required>
							<option value disabled selected>{{$t('edit-profile.select')}}</option>
							<option
								:selected="user.pais_idpais === pais.idpais"
								v-bind:value="pais.idpais"
								:key="pais.idpais"
								v-for="pais in paises"
							>{{ pais.nombre }}</option>
						</select>
					</div>
					<div class="col-12 col-md-4 mb-3">
						{{$t('edit-profile.gender')}}
						<select v-model="perfil.sexo" required>
							<option value disabled selected>{{$t('edit-profile.select')}}</option>
							<option value="m">{{$t('edit-profile.male')}}</option>
							<option value="f">{{$t('edit-profile.female')}}</option>
						</select>
					</div>
				</div>
			</div>

			<div class="form-group mt-4">
				{{$t('edit-profile.biography')}}
				<textarea
					v-model="perfil.biografia"
					name="message"
					:placeholder="$t('edit-profile.tell-us')"
					class="form-control biography-textarea"
					rows="10"
					cols="30"
				></textarea>
			</div>

			<div class="btn-save text-center text-md-right mb-5">
				<button type="submit" class="btn" id="save-profile-button">{{$t('edit-profile.save')}}</button>
			</div>
            
		</form>
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import router from "@/router";
import axios from "axios";
import { redirectToSavedUrl } from "@/Utils/Utils";

export default {
	name: "EditarPerfil",
	data() {
		return {
			paises: [],
			perfil: JSON.parse(localStorage.getItem('usuario')),
		};
	},
	methods: {
		...mapActions(["actualizarPerfil"]),
        
        async saveProfile(profile) {
            await this.actualizarPerfil(profile);
            router.push({ name: "profile-update-success"});
        }
	},
	computed: {
		...mapState(["user"])
	},
	created() {
		window.scrollTo(0, 0);
		let currentObj = this;
		axios
			.get(process.env.VUE_APP_API_URL + "countries")
            .then(response => (currentObj.paises = response.data));
        this.perfil.sexo = this.perfil.sexo.toLowerCase();
	}
};
</script>

<style lang="scss" scoped>
.container {
	h1 {
		color: $hf-sec-pine;
		font-size: 1.8em;
		//border-bottom: solid rgb(231, 231, 231) 0.5px;
		padding-bottom: 10px;
		margin-bottom: 20px;
		text-align: right;
	}

	.form-control {
		text-align: left;
		height: 48px;
	}

	.biography-textarea {
		height: 200px;
	}

	.btn-save {
		margin-top: 10px;
		button {
			color: white;
			background-color: $hf-sec-pine;
		}
	}

	select {
		appearance: none;
		cursor: pointer;
		height: 48px;
		outline: 0;
		border: solid 1px rgb(214, 214, 214);
		width: 100%;
		padding-left: 10px;

		background-color: white;
		background-image: url("~@/assets/down-chevron.svg");
		background-repeat: no-repeat, repeat;
		background-position: right 10px top 55%, 0 0;
		background-size: 1.1em auto, 100%;
	}
}
</style>



