<template>
    <div class="currency-switcher">
        
        <div class="dropdown">
            <button class="dropdown-toggle" data-toggle="dropdown">
                <span class="icon">$</span> 
                <span class="currency-text">{{ visitorCurrency }}</span>
                <i class="fas fa-chevron-down"></i>
            </button>

            <div class="dropdown-menu">
                <a
                    v-for="(currency, index) in currencyConversionTable"
                    :key="index"
                    class="dropdown-item"
                    href="#"
                    @click.prevent="changeCurrency(currency.moneda)"
                >
                    $ {{ currency.moneda }}
                </a>
            </div>
        </div>
        
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    name: "CurrencySwitcher",
    data() {
        return {
            defaultVisitorCurrency: "USD",
            selectedCurrency: null,
            showCurrencyBanner: false,
        };
    },
    computed: {
        ...mapState("exchange", [
            "currencyConversionTable", 
            "visitorCurrency",
        ]),
    },
    methods: {
        ...mapActions("exchange", [
            "fetchCurrencyConversionTable",
            "setVisitorCurrency",
        ]),
        
        changeCurrency(currency) {
            this.selectedCurrency = currency;
        },
        checkFirstVisit() {
            if (localStorage.getItem('hasSeenCurrencyBanner') !== 'true') {
                this.showCurrencyBanner = true;
            }
        },
        closeCurrencyBanner() {
            this.showCurrencyBanner = false;
            localStorage.setItem('hasSeenCurrencyBanner', 'true');
        }
    },    

    created() {

        this.fetchCurrencyConversionTable()

            .then(() => {

                let localStorageVisitorCurrency = localStorage.getItem("visitorCurrency");

                // If there is no visitor currency set, set it to default, otherwise set it to the one in local storage
                if (!localStorageVisitorCurrency) {
                    localStorageVisitorCurrency = this.defaultVisitorCurrency;
                    localStorage.setItem("visitorCurrency", localStorageVisitorCurrency);
                }

                // Set the visitor currency to the store
                this.setVisitorCurrency(localStorageVisitorCurrency);

                // Set the menu to the selected currency
                this.selectedCurrency = localStorageVisitorCurrency;

                // Check if it's the user's first visit
                this.checkFirstVisit();
            });

    },
    watch: {
        selectedCurrency(newCurrency) {
            this.setVisitorCurrency(newCurrency);
            localStorage.setItem("visitorCurrency", newCurrency);
        },
    },

};
</script>

<style lang="scss" scoped>

.currency-switcher {
    
    .dropdown-toggle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #fef7f0;
        color: #f48225;
        border: 1px solid #f48225;
        border-radius: 30px;
        padding: 15px 15px;
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;
        width: auto;

        &:after {
          display: none;    
        }
        
        &:hover {
            background-color: #fbe9dd;
        }

        .icon {
            font-weight: bold;
            margin-right: 5px;
        }

        .currency-text {
            margin-right: 5px;
        }

        i {
            font-size: 12px;
        }
        
        @media (min-width: $md) {
            padding: 6px 15px;
            border-radius: 20px;
        }
    }

    .dropdown-menu {
        border: 1px solid #f48225;
        border-radius: 20px;
        background-color: #fff;
        min-width: 100%;
        max-width: 100%;
        text-align: center;
        overflow: hidden;

        @media (max-width: $md) {
            min-width: 100px !important;
        }

        .dropdown-item {
            color: #f48225;
            padding: 5px;

            &:hover {
                background-color: #fadbca;
            }
        }
    }
}
</style>
