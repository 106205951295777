<template>
    <div class="success-screen">
        <div class="success-screen__back-arrow" @click="goBack">
            <i class="fas fa-chevron-left success-screen__back-arrow--large"></i>
        </div>
        <h1 class="success-screen__title">{{ $t(titleKey) }}</h1>
        <p class="success-screen__message">{{ $t(messageKey) }}</p>
        <Button @click="onClick" class="new-btn" variant="sec-pine">{{ $t(buttonTextKey) }}</Button>
    </div>
</template>

<script>
import Button from "@/components/Ui/Button.vue";
import { redirectToSavedUrl } from "@/Utils/Utils";
export default {
    name: 'SuccessScreen',
    props: {
        titleKey: {
            type: String,
            required: true
        },
        messageKey: {
            type: String,
            required: true
        },
        nextRouteName: {
            type: String,
            required: false
        },
        nextRouteParams: {
            type: Object,
            required: false,
            default: () => ({})
        },
        buttonTextKey: {
            type: String,
            required: true
        },
        backNavigationSteps: {
            type: Number,
            required: false,
            default: null,
        }
    },
    components: {
        Button
    },
    methods: {
        onClick() {
            if (this.nextRouteName) {
                this.$router.push({ name: this.nextRouteName, params: this.nextRouteParams });
            } else {
                redirectToSavedUrl(this.$router);
            }
        },
        goBack() {
            if (this.backNavigationSteps !== null) {
                this.$router.go(-this.backNavigationSteps);
            } else {
                this.onClick()
            }
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/new-assets/styles";

.success-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;

    &__title {
        font-size: 2rem;
        font-weight: bold;
    }

    &__message {
        padding: 30px;
        text-align: center;
        max-width: 600px;
    }

    &__back-arrow {
        position: absolute;
        padding: 20px;
        top: 80px;
        left: 20px;
        cursor: pointer;

        &--large {
            font-size: 1.5rem; // Ajusta este valor a tu gusto
        }
    }

    @media (min-width: $md) {
        &__back-arrow {
            top: 130px;
            left: 40px;
        }
        
    }
}
</style>