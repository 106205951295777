<!-- AppVersionCheck.vue -->
<template>
    <b-modal 
        id="app-out-of-date-modal" 
        :title="$t('version-control.title')"
        no-close-on-backdrop
        no-close-on-esc
        hide-header
    >
        <i class="icon fas fa-sync-alt" />
        <div class="message">{{ $t('version-control.message') }}</div>
        <template v-slot:modal-footer>
            <Button @click="updateAndClose" class="new-btn" variant="sec-pine">
                {{ $t('version-control.update') }}
            </Button>
        </template>
    </b-modal>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Button from "@/components/Ui/Button.vue";

export default {
    components: {Button},
    data() {
        return {
        }
    },
    computed: {
        ...mapState('appVersionCheck', [
            'appOutOfDate'
        ]),
    },
    methods: {
        ...mapActions('appVersionCheck', [
            'setAppOutOfDate'
        ]),

        updateAndClose() {
            // Reload ignoring cache
            window.location.reload(true);
        }
    },
    watch: {
        appOutOfDate(newVal) {
            immediate: false    
            if (newVal) {
                this.$bvModal.show('app-out-of-date-modal');
            }
        },
    }
};
</script>

<style lang="scss" scoped>
@import "@/new-assets/styles";

::v-deep .modal-footer {
    justify-content: center;;
}

.icon {
    text-align: center;
    color: $hf-office;
    font-size: 4rem;
    width: 100%;
    margin: 40px 0 50px 0;
}

.message {
    text-align: center;
    margin-bottom: 40px;
}
</style>