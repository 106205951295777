import axios from 'axios';
import * as types from './types';
import apiUrls from "@/new-constants/apiUrls";
import { formatDate } from "@/Utils/Utils";

function getData(state) {
    return {
        'accommodationId': state.accommodationId,
        'initDate': state.initDate,
        'endDate': state.endDate,
        'guests': state.guests,
    };
}

function isValidState(state) {
    return state.initDate !== '' && state.endDate !== '' && state.accommodationId > 0;
}

export default {
    
    setSimulation({ commit }, simulation) {
        commit(types.SET_SIMULATION, simulation);
    },
    
    setAccommodationId({ commit }, accommodationId) {
        commit(types.SET_ACCOMMODATION_ID, accommodationId);
    },
    
    setInitDate({ commit }, initDate) {
        const date = (initDate == null) ? null : formatDate(initDate);
        commit(types.SET_INIT_DATE, date);
    },
    
    setEndDate({ commit }, endDate) {
        const date = (endDate == null) ? null : formatDate(endDate);
        commit(types.SET_END_DATE, date);
    },
    
    setTotalDays({ commit }, totalDays) {
        commit(types.SET_TOTAL_DAYS, totalDays);
    },
    
    setGuests({ commit }, guests) {
        commit(types.SET_GUESTS, guests);
    },

    async getAvailable({ commit, dispatch, state }) {
        if (isValidState(state)) {
            const data = getData(state);
            await axios.get(`${apiUrls.available}`, { params: data })
                .then(resp => {
                    dispatch('setAvailable', resp.data.available)
                })
        }
    },

    setAvailable({ commit }, data) {
        commit(types.SET_AVAILABLE, data);
    },

    async getSimulation({ commit, dispatch, state }) {
        if (isValidState(state)) {
            const data = getData(state);
            await axios.get(`${apiUrls.simulation}`, { params: data })
                .then(resp => {
                    dispatch('setSimulation', resp.data.simulation)
                })
        }
    },

};
