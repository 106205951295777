import axios from 'axios';
import * as types from './types';
import apiUrls from '@/new-constants/apiUrls';

export default {

    async fetchReservations({ commit, state, dispatch }, { page, perPage, user, type }) {
        const url = `${apiUrls.reservations}?page=${page}&perPage=${perPage}&user=${user}&type=${type}`;

        const response = await axios.get(url);
        const reservations = response.data;

        dispatch('setReservations', reservations);
    },

    setReservations({ commit }, reservations) {
        commit(types.SET_RESERVATIONS, reservations);
    },

    async setReservationAsPaid({ commit, dispatch }, reservation) {

        const url = `${apiUrls.setReservationAsPaid}`;
        const data = { id_reserva: reservation.idreserva };

        try {
            const response = await axios.post(url, data);

            if (response.status !== 200) {
                console.error('Hubo un error al marcar la reserva como pagada');
            }
            
            return response.data;

        } catch (error) {
            console.error('Hubo un error al comunicarse con el servidor', error);
        }
    },
    
    async setReservationAsCanceled({ commit, dispatch }, reservation) {

        const url = `${apiUrls.reservations}/${reservation.idreserva}`;
        const data = {status: 'canceled'};

        try {
            const response = await axios.put(url, data);

            if (response.status !== 200) {
                console.error('Hubo un error al cancelar la reserva');
            }
            
            return response.data;

        } catch (error) {
            console.error('Hubo un error al comunicarse con el servidor', error);
        }
    }
    
};
