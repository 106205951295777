import axios from 'axios';
import * as types from './types';
import apiUrls from "@/new-constants/apiUrls";

export default {

    async setUserType({ commit }, data) {
        try {
            const response = await axios.patch(apiUrls.userType, data);
            commit('SET_USER_TYPE', response.data.user.tipo);

        } catch (error) {
            throw new Error(error);
        }
    },
    
    async setUserCountry({ commit }, data) {
        try {
            const response = await axios.patch(apiUrls.userCountry, data);
            commit('SET_USER_COUNTRY', response.data.user.pais);

        } catch (error) {
            throw new Error(error);
        }
    }
};
