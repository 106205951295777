<template>
    <div class="register-main-div">

        <!-- Logo -->
        <div class="logo desktop-only">
            <img :src="assets.logoVerticalSrc" class="desktop-only" alt="HouseAndFlats logo"/>
        </div>

        <!-- Sign message -->
        <div class="log-message">
            {{ message }}
        </div>
        
        <template v-if="isRegister">
            <Progress :step="step"/>

            <!-- Step 1 -->
            <Step1 v-if="step == 1" @next-step="nextStep" />

            <!-- Step 2 -->
            <Step2 v-if="step == 2" @next-step="nextStep" />

            <!-- Step 3 -->
            <Step3  v-if="step == 3" @next-step="nextStep" />
        </template>
        
        <!-- Login -->
        <Login v-if="isLogin" />
        
    </div>
</template>


<script>

import Button from "@/components/Ui/Button.vue";
import ValidatedInput from "@/components/ValidatedFields/ValidatedInput.vue";
import Progress from "@/pages/Registration/Register/RegisterProgress.vue" 
import Step1 from "@/pages/Registration/Register/Step-1"
import Step2 from "@/pages/Registration/Register/Step-2"
import Step3 from "@/pages/Registration/Register/Step-3"
import Login from "@/pages/Registration/Login/Login.vue"
import { redirectToSavedUrl } from "@/Utils/Utils";
import { assets } from "@/new-constants/assets";


export default {
    name: "Register",
    components: {
        Button,
        ValidatedInput,
        Progress,
        Step1,
        Step2,
        Step3,
        Login
    },
    data() {
        return {
            step: null,
            assets
        };
    },
    methods: {

        nextStep() {
            // Incrementa el paso si no estás en el último paso
            if (this.step < 3) {
                this.step++;
                this.$router.push({ path: `/registro/step-${this.step}` });
            } else {
                // Si ya estás en el paso 3, redirige a la URL guardada
                redirectToSavedUrl(this.$router);
            }
        },
        
        updateStep() {
            this.step = parseInt(this.$route.params.step) || 1;
            if (this.isRegister && !this.$route.params.step) {
                this.$router.replace({ path: '/registro/step-' + this.step });
            }
        }
    },
    computed: {
        isLogin() {
            return this.$route.name === 'login';
        },
        isRegister() {
            return this.$route.name === 'register';
        },
        message() {
            return this.isLogin ? this.$t('login.sign-in') : this.$t('login.register');
        }
    },
    created() {
        this.updateStep();
    },
    watch: {
        $route() {
            this.updateStep();
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@/new-assets/styles";

.register-main-div {
    margin: 0 auto;
    width: 100%;
    padding: 0px 20px 100px 20px;
}

.logo {
    display: flex;
    justify-content: center;
    img {
        width: 150px;
    }
    padding-top: 51px;
}

.log-message {
    padding-top: 20px;
    text-align: center;
    font-size: 1em;
    
    @media (max-width: $md) {
        font-size: 2em;
    }
}

</style>