<template>
    <footer class="footer">
        <div class="footer__content">
            <!-- Logo -->
            <router-link :to="routes.home" class="footer__logo">
                <img class="logo" :src="assets.logoSrc" alt="House&Flats Logo" />
            </router-link>

            <div class="d-flex footer__info">
                <!-- Navigation Links -->
                <nav class="footer__nav">
                    <ul>
                        <li><router-link :to="routes.contact">{{ $t('footer.contact-form') }}</router-link></li>
                        <li><router-link :to="routes.privacy">{{ $t('footer.privacy') }}</router-link></li>
                        <li><router-link :to="routes.faq">{{ $t('footer.faq') }}</router-link></li>
                        <li><router-link :to="routes.terms">{{ $t('footer.terms') }}</router-link></li>
                        <li><router-link :to="routes.investors">{{ $t('footer.investors') }}</router-link></li>
                    </ul>
                </nav>

                <!-- Contact Information -->
                <div class="footer__contact">
                    <ul>
                        <li>{{ $t('footer.contact.title') }}</li>
                        <li>
                            <img :src="assets.emailIconSrc" alt="Email Icon" />
                            <a :href="routes.email">
                                {{ $t('footer.contact.email') }}
                            </a>
                        </li>
                        <li>
                            <img :src="assets.phoneIconSrc" alt="Phone Icon" /> 
                            {{ $t('footer.contact.phone') }}
                        </li>
                        <li>
                            <img :src="assets.locationIconSrc" alt="Location Icon" />
                            {{ $t('footer.contact.address') }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <!-- Footer Bottom -->
        <div class="footer__bottom">
            <p>© 2024 House and Flats. {{ $t('footer.rights') }}</p>
            <div>
                <a :href="routes.terms">{{ $t('footer.terms') }}</a>
                <a :href="routes.privacy">{{ $t('footer.privacy') }}</a>
                <a :href="routes.cookies">{{ $t('footer.cookies') }}</a>
            </div>
        </div>
    </footer>
</template>

<script>
import { routes } from '@/new-constants/routes';
import { assets } from '@/new-constants/assets';

export default {
    data() {
        return {
            routes,
            assets
        };
    }
};
</script>

<style lang="scss" scoped>

.footer {
    background-color: #38383A;
    color: #fff;
    padding: 96px 80px 40px 80px;

    &__content {
        display: flex;
        justify-content: space-between;

        @media (max-width: $md) {
            flex-direction: column;
            align-items: center;
            gap: 40px;
        }
    }

    &__logo {
        img {
            width: 232px;
            height: auto;
            @media (max-width: $md) {
                margin-bottom: 16px;
            }
        }
    }
    
    &__info {
        display: flex;
        gap: 96px;

        @media (max-width: $md) {
            flex-direction: column;
            align-items: center;
            gap: 40px;
        }
    }

    &__nav ul {

        display: none;
        @media (min-width: $md) {
            display: block;
        }
        
        list-style: none;
        padding: 0;

        li {
            margin-bottom: 8px;

            a {
                color: #fff;
                text-decoration: none;
                font-size: 16px;

                &:hover {
                    text-decoration: underline !important;
                }
            }
        }
    }

    &__contact ul {
        list-style: none;
        padding: 0;

        li {
            margin-bottom: 12px;

            &:first-child {
                margin-bottom: 16px;
                @media (max-width: $md) {
                    justify-content: center;
                    border-bottom: 2px solid #666;
                    line-height: 2;
                }
            }
            
            &:nth-child(2) {
                &:hover {
                    text-decoration: underline !important;
                }
            }
            
            display: flex;
            align-items: center;

            img {
                width: 16px;
                height: 16px;
                margin-right: 20px;
            }

            a {
                color: #fff;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &__bottom {
        margin-top: 128px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #666;
        padding-top: 32px;

        @media (max-width: $md) {
            flex-direction: column;
            text-align: center;
            gap: 16px;
        }


        p {
            margin: 0;
            font-size: 14px;
        }

        a {
            color: #fff;
            text-decoration: none;
            margin-right: 16px;

            &:hover {
                text-decoration: underline !important;
            }
        }
    }
}

</style>