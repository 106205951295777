<template>
    <div class="register-form">
        
        <!-- Firstname -->
        <ValidatedInput
            fieldName="firstname"
            v-model="formData.first_name"
            :label="$t('login.firstname')"
            :placeholder="$t('login.firstname-placeholder')"
            :validationObject="$v.formData.first_name"
            :apiError="getApiError('firstname')"
            @clear-error="clearFieldError"
        />

        <!-- Lastname -->
        <ValidatedInput
            fieldName="lastname"
            v-model="formData.last_name"
            :label="$t('login.lastname')"
            :placeholder="$t('login.lastname-placeholder')"
            :validationObject="$v.formData.last_name"
            :apiError="getApiError('lastname')"
            @clear-error="clearFieldError"
        />

        <!-- Email -->
        <ValidatedInput
            fieldName="email"
            v-model="formData.email"
            :label="$t('bank.owner-email')"
            :placeholder="$t('bank.owner-email-placeholder')"
            :validationObject="$v.formData.email"
            :apiError="getApiError('email')"
            @clear-error="clearFieldError"
        />

        <!-- Password -->
        <ValidatedInput
            type="password"
            fieldName="password"
            v-model="formData.password"
            :label="$t('login.password')"
            :placeholder="$t('login.password-placeholder')"
            :validationObject="$v.formData.password"
            :apiError="getApiError('password')"
            @clear-error="clearFieldError"
        />

        <!-- Continue button -->
        <div class="continue-button-div">
            <Button @click="handleSubmit" class="new-btn" variant="sec-pine">
                {{ $t('login.continue') }}
            </Button>
        </div>

        <!-- Login link -->
        <div class="login-link">
            {{$t('register-new.already-account')}}
            <router-link :to="{ name: 'login' }">{{$t('register-new.sign-in')}}</router-link>
        </div>

        <GoogleButton />
        
    </div>
</template>

<script>

import { mapActions } from "vuex";
import ValidatedInput from "@/components/ValidatedFields/ValidatedInput.vue";
import Button from "@/components/Ui/Button.vue";
import { registerValidations } from "@/components/ValidatedFields/registerRules";
import {getKeyByValue} from "@/Utils/Utils";
import GoogleButton from "@/pages/Registration/GoogleButton.vue";

export default {
    name: "Step1",
    components: {
        GoogleButton,
        ValidatedInput,
        Button
    },
    validations: registerValidations,
    props: {
    },
    data() {
        return {
            formData: {
                first_name: '',
                last_name: '',
                email: '',
                password: ''
            },
            // Api keys are different from the front keys, so I map them
            fieldErrorMapping: {
                email: 'correo',
                firstname: 'nombre',
                lastname: 'apellido',
                password: 'hash'
            },
            error: {
                // We need to define the error structure to keep reactivity working
                correo: '',
                nombre: '',
                apellido: '',
                hash: '',
            },
        };
    },
    methods: {
        
        ...mapActions([
            "register",
            "createSession",
        ]),
        
        async handleSubmit() {
            this.$v.$touch();

            // Don't submit if there are errors
            if (this.$v.$invalid) {
                return;
            }

            let data = {
                nombre: this.formData.first_name,
                apellido: this.formData.last_name,
                correo: this.formData.email,
                hash: this.formData.password
            };

            try {
                const response = await this.register(data);
                await this.createSession(response);
                // emit the event to the parent component
                this.$emit('next-step');
            } catch (e) {
                this.handleError(e);
            }
        },

        getApiError(fieldName) {
            const apiFieldName = this.fieldErrorMapping[fieldName];
            return this.error[apiFieldName] || '';
        },

        clearFieldError(fieldName) {
            const apiFieldName = this.fieldErrorMapping[fieldName];
            if (apiFieldName) {
                this.error[apiFieldName] = '';
            }
        },

        handleError(error) {
            const errors = error.response.data;
            for (let item in errors) {
                if (errors.hasOwnProperty(item)) {
                    for(let i in errors[item]) {
                        this.error[item] = this.$t("validations.error-" + getKeyByValue(this.fieldErrorMapping, item) + "-" + errors[item][i]);
                    }
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@/new-assets/styles";

.register-form {
    margin: 0 auto 0 auto;
    padding-top: 60px;
    width: 100%;
    max-width: 400px;
}

.login-link {
    text-align: center;
    padding-top: 50px;
}

.continue-button-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
}

</style>