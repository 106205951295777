<template>
    <div class="container">

        <h1>{{ $t("dashboard-side-menu.my-accommodations") }}</h1>

        <!-- Add accommodation icon -->
        <router-link to="/registro-alojamiento">
            <div class="add-accommodation">
                <img :src="require(`@/assets/plus-circle.svg`)" /> 
                <span class="add-accommodation-link">{{ $t("dashboard-side-menu.add-accommodation") }}</span>
            </div>
        </router-link>
        
        <DashboardSearch @search="handleSearch" />

        <div v-show="loader">
            <Loader />
        </div>

        <div v-show="!loader">
            <div v-if="accommodations.length">
                <div class="row">

                    <!-- Main loop -->
                    <div v-for="accommodation in accommodations"
                         :key="accommodation.idalojamiento"
                         class="col-md-4 col-sm-12 mb-5" 
                         @show="getPhotos(accommodation.idalojamiento)"
                    >
                        <router-link :to="accommodationLink(accommodation.idalojamiento)" class="card">


                            <!-- Pictures -->
                            <CarouselList 
                                :idAlojamiento="accommodation.idalojamiento"
                                :externalReference="accommodation.external_reference"
                                :photos="accommodation.gallery"
                            />

                            <!-- Actions icons-->
                            <div class="container__actions">
                                <div>

                                    <!-- Favorite -->
                                    <img v-if="accommodation.alojamiento_idalojamiento"
                                        @click="wishList(accommodation.idalojamiento)" class="action-item"
                                        src="@/assets/corazon.svg" />
                                    <img v-else @click="wishList(accommodation.idalojamiento)" class="action-item"
                                        src="@/assets/corazon-borde.svg" />

                                    <!-- Share -->
                                    <img @click="$bvModal.show('modal-' + accommodation.idalojamiento)" class="action-item"
                                        src="@/assets/compartir.svg" />
                                </div>

                                <div>

                                    <!-- Date blocking -->
                                    <router-link v-b-popover.top.hover="$t('my-accommodations.date-lock')"
                                        :to="'/bloquear/' + accommodation.idalojamiento">
                                        <img src="@/assets/calendar.svg" />
                                    </router-link>

                                    <!-- Edit -->
                                    <router-link v-b-popover.top.hover="$t('my-accommodations.edit')"
                                        :to="'/editar-alojamiento/' + accommodation.idalojamiento">
                                        <img src="@/assets/editar-morado.svg" />
                                    </router-link>

                                    <!-- Clone -->
                                    <img v-b-popover.top.hover="$t('my-accommodations.clone')"
                                        @click="clonar(accommodation.idalojamiento)" class="action-item"
                                        src="@/assets/copiar.svg" />

                                    <!-- Delete -->
                                    <img v-b-popover.top.hover="$t('my-accommodations.remove-accommodation')"
                                        @click="eliminarAlojamiento(accommodation.idalojamiento)" class="action-item"
                                        src="@/assets/borrar.svg" />

                                </div>

                            </div>

                            <!-- Information -->
                            <div class="card-body  card-body-title-acc">

                                <!-- Title -->
                                <router-link class="card-title" :to="'alojamiento/' + accommodation.idalojamiento">{{
                                    JSON.parse(accommodation.nombre)[$i18n.locale] }}
                                </router-link>

                                <div>
                                    <div class="availability-price">

                                        <!-- Information -->
                                        <div class="pricing" style="display:flex">

                                            <!-- Status icon -->
                                            <div v-b-popover.hover="approved(accommodation) ? $t('my-accommodations.approved') : $t('my-accommodations.pending')"
                                                :class="{ 'approved': approved(accommodation), 'pending': pending(accommodation) }">
                                                <i class="fas fa-circle"></i>
                                            </div>

                                            <!-- Price -->
                                            <!-- TODO: imrprove convertAndFormatPrice filter to use with arguments -->
                                            <div class="price" style="margin-left: 5px">

                                                <div v-if="accommodationDailyRentalMode(accommodation)">
                                                    <div>
                                                        {{ { 'amount': accommodation.arriendo_diario, 'currency': accommodation.moneda} | convertAndFormatPrice }} / {{ $t('accommodation.per-day') }}
                                                    </div>
                                                </div>

                                                <div v-if="accommodationMonthlyRentalMode(accommodation)">
                                                    <div>
                                                        {{ { 'amount': accommodation.arriendo, 'currency': accommodation.moneda } | convertAndFormatPrice }} / {{ $t('accommodation.per-month') }}
                                                    </div>
                                                </div>

                                                <div v-if="accommodationDualRentalMode(accommodation)">
                                                    <div>
                                                        {{ { 'amount': accommodation.arriendo, 'currency': accommodation.moneda } | convertAndFormatPrice }} / {{ $t('accommodation.per-month') }}
                                                    </div>
                                                    <br />
                                                    <div class="reservation-per-day">
                                                        * {{ { 'amount': accommodation.arriendo_diario, 'currency': accommodation.moneda } | convertAndFormatPrice }}
                                                        {{ $t("reservation.per-day") }}
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                        {{ accommodation.direccion }}
                                    </div>
                                </div>
                            </div>
                        </router-link>

                        <b-modal :id="'modal-' + accommodation.idalojamiento" hide-footer title="Compartir alojamiento">
                            <p>{{ $t("my-accommodations.share-message") }}:</p>

                            <p id="textoACopiar">
                                {{
                                    url +
                                    "/alojamiento/" +
                                    accommodation.idalojamiento
                                }}
                                <button @click="obtieneUrl()"
                                    :id="`copy-accommodation-${accommodation.idalojamiento}-button`">
                                    <i class="fas fa-copy" />
                                </button>
                            </p>

                            <p>{{ $t("my-accommodations.share-socials") }}:</p>
                            <div class="socials">
                                <a target="_blank" :href="'whatsapp://send?text=' +
                                    url +
                                    '/alojamiento/' +
                                    accommodation.idalojamiento
                                    ">
                                    <i class="fab fa-whatsapp" />
                                </a>
                                <a target="_blank" :href="'http://www.facebook.com/sharer/sharer.php?u=' +
                                    url +
                                    '/alojamiento/' +
                                    accommodation.idalojamiento
                                    ">
                                    <i class="fab fa-facebook-f" />
                                </a>
                                <a target="_blank" :href="'https://twitter.com/share?url=' +
                                    url +
                                    '/alojamiento/' +
                                    accommodation.idalojamiento +
                                    '&via=houseandflatschile'
                                    ">
                                    <i class="fab fa-twitter" />
                                </a>
                            </div>
                        </b-modal>
                    </div>
                </div>

                <Paginator :pages="paginateData.last_page" @setPage="reloadData" />
            </div>
            <div v-if="accommodations.length <= 0 && s == ''">
                <h4>
                    {{ $t("my-accommodations.no-yet") }}
                    <router-link to="/registro-alojamiento">{{
                        $t("my-accommodations.here")
                    }}</router-link>
                </h4>
            </div>

            <div v-if="accommodations.length <= 0 && s != ''">
                <h4 style="width:100%;text-align: center;">
                    {{ $t("my-accommodations.not-found") }}
                </h4>
            </div>

        </div>
    </div>
</template>

<script>
import Loader from "@/components/Loader/Loader";
import { mapState, mapMutations, mapActions } from "vuex";
import CarouselList from "@/components/Carousel/CarouselList";
import axios from "axios";
import Paginator from "@/components/Paginator/Paginator";
import DashboardSearch from "@/components/DashboardSearch/DashboardSearch";
import priceMixin from "@/mixins/priceMixin";

export default {
    name: "Dashboard",
    components: {
        CarouselList,
        Loader,
        Paginator,
        DashboardSearch,
    },
    mixins: [priceMixin],

    data() {
        return {
            url: process.env.VUE_APP_SHARE_URL,
            api: process.env.VUE_APP_API_URL,
            loader: true,
            accommodations: [],
            paginateData: {},
            s: ''
        };
    },
    methods: {
        ...mapActions(["eliminarAlojamiento", "wishList", "clonar"]),

        accommodationLink(accommodationId) {
            if(this.$route.query.fecha_inicio && this.$route.query.fecha_termino)
                return "/alojamiento/" + accommodationId + '/' + this.$route.query.fecha_inicio + '/' + this.$route.query.fecha_termino;
            else
                return "/alojamiento/" + accommodationId;
        },
        
        obtieneUrl() {
            var codigoACopiar = document.getElementById("textoACopiar");
            seleccion.selectNodeContents(codigoACopiar);
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(seleccion);
            var res = document.execCommand("copy");
            window.getSelection().removeRange(seleccion);
        },
        formateaUrl(value) {
            let url = "";
            return JSON.parse((url = value.split(" ").join("-")));
        },
        getAccommodations() {
            this.loader = true;
            const urlParams = new URLSearchParams(window.location.search);
            let uri = `${this.api}accommodations/get/accommodations`;
            uri = `${uri}?${urlParams.toString()}`;
            this.s = urlParams.get('s');
            axios.get(uri).then((response) => {
                this.accommodations = response.data.data;
                this.loader = false;
                this.paginateData = response.data;
            });
        },
        setUrl(value) {
            const urlParams = new URLSearchParams(window.location.search);
            urlParams.set("s", value);
            window.history.pushState(
                null,
                "Search",
                "/dashboard?" + urlParams.toString()
            );
        },
        reloadData(page) {
            console.log(page);
            const path = this.paginateData.first_page_url.replace(
                this.paginateData.path,
                ""
            );
            let uri = `${this.api}accommodations/get/accommodations`;
            const urlParams = new URLSearchParams(path);
            urlParams.set("page", page);
            window.history.pushState(
                null,
                "Search",
                "/dashboard?" + urlParams.toString()
            );
            uri = `${uri}?${urlParams.toString()}`;
            this.setScrollTop();
            this.loader = true;
            axios.get(uri).then((response) => {
                this.accommodations = response.data.data;
                this.loader = false;
                this.paginateData = response.data;
                this.setScrollTop();
            });
        },
        handleSearch(e) {
            const urlParams = new URLSearchParams(window.location.search);
            urlParams.delete("page");
            urlParams.set("s", e);
            let uri = `${this.api}accommodations/get/accommodations`;
            window.history.pushState(
                null,
                "Search",
                "/dashboard?" + urlParams.toString()
            );
            uri = `${uri}?${urlParams.toString()}`;
            this.loader = true;
            axios.get(uri).then((response) => {
                this.accommodations = response.data.data;
                this.loader = false;
                this.paginateData = response.data;
                this.setScrollTop();
                this.$refs["dashboardInput"].focus();
            });
        },
        setScrollTop() {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        },
        approved(accommodation) {
            return accommodation.aprobado === 1;
        },
        pending(accommodation) {
            return accommodation.aprobado === 2;
        },
    },
    computed: {
        ...mapState([
            "user",
            "heart"
        ]),
        totalDays() {
            const startDate = moment(this.$route.params.fecha_inicio);
            const finalDate = moment(this.$route.params.fecha_termino);
            return finalDate.diff(startDate, 'days');
        }
    },
    created() {
        this.getAccommodations();
        window.scrollTo(0, 0);
    },
};
</script>


<style scoped lang="scss">
.container {
    h1 {
        color: $hf-sec-pine;
        font-size: 1.8em;
        //border-bottom: solid rgb(231, 231, 231) 0.5px;
        padding-bottom: 10px;
        margin-bottom: 20px;
        text-align: right;
    }

    &__actions {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;

        div * {
            margin: 0 4px;
        }
    }

    .availability-price {
        padding: 20px 0;
    }

    .action-item {
        cursor: pointer;
    }

    .add-accommodation {
        display: flex;
        align-items: center;
        //margin-bottom: 20px;
        font-size: 1.5em;
        color: $hf-sec-pine;

        img {
            width: 30px;
        }
        .add-accommodation-link {
            font-size: 1em;
            margin-left: 8px;
        }
    }
}

.card-body-title-acc {
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px;
}

.socials {
    font-size: 2em;
    display: flex;
    justify-content: space-around;

    a {
        color: $hf-sec-pine;
    }
}

.card-title {
    color: $hf-sec-pine;
    font-size: 18px;

    &:hover {
        text-decoration: none;
    }
}

// .card-body {
//     border-top: solid #f7f7f7 10px;
// }

.card-footer {
    background-color: $hf-sec-pine;
    color: white;
}

.approved {
    color: green;
    display: inline-block;
}

.pending {
    color: red;
    display: inline-block;
}

.loader {
    .loader-img {
        width: 150px;
        margin: auto;
        display: block;
    }

    .loader-text {
        color: $hf-sec-pine;
        text-align: center;
        font-size: 1.5em;
    }
}
</style>